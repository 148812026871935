import React, { InputHTMLAttributes } from 'react';
import { FaAsterisk } from 'react-icons/fa';
import InputMask from 'react-input-mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error: boolean;
  errorText: string;
  label?: string;
  isRequired?: boolean;
}
const InputComponent: React.FC<InputProps> = ({
  label,
  error,
  errorText,
  isRequired,
  type,
  ...rest
}) => (
  <div className="input-wrapper">
    {label ? (
      <label className="input-label">
        {label}

        {isRequired ? (
          <span className="required">
            <FaAsterisk size={8} />
          </span>
        ) : null}
      </label>
    ) : null}
    {type === 'tel' ? (
      <InputMask
        mask={'+380999999999'}
        className={`input-field ${error ? 'error' : ''}`}
        alwaysShowMask
        maskChar={null}
        {...rest}
      />
    ) : (
      <input type={type} className={`input-field ${error ? 'error' : ''}`} {...rest} />
    )}
    {error ? <p className="input-error">{errorText}</p> : null}
  </div>
);
export default InputComponent;
