import React from 'react';
import License from '../../assets/images/license.jpg';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">{t('page_product_quality_title_text1')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>
            <strong>{t('page_product_quality_title_text2')}</strong>
          </p>
          <p>{t('page_product_quality_text1')}</p>
          <p>{t('page_product_quality_text2')}</p>
          <ul>
            <li>{t('page_product_quality_list_text1')}</li>
            <li>{t('page_product_quality_list_text2')}</li>
            <li>{t('page_product_quality_list_text3')}</li>
          </ul>
          <p>{t('page_product_quality_text3')}</p>
          <p>{t('page_product_quality_text4')}</p>
        </div>
        <div className="col-md-6">
          <div className="singleImg">
            <img src={License} alt="License" />
          </div>
        </div>
      </div>
    </div>
  );
};
