import { Product } from '../responseTypes';
import { RESET_WISHLIST, SET_WISHLIST, SET_WISHLIST_LOADING } from '../actionTypes';

interface WishlistState {
  products: Product[];
  loading: boolean;
}

const initialState: WishlistState = {
  products: [],
  loading: false
};

export default function wishlistReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_WISHLIST:
      return {
        ...state,
        products: action.payload
      };
    case SET_WISHLIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case RESET_WISHLIST:
      return initialState;
    default:
      return state;
  }
}
