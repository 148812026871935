import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

type seoType = {
  customTitle?: string;
  customDescription?: string;
  customKeywords?: string;
};

export default ({ customTitle, customDescription, customKeywords }: seoType) => {
  const { t } = useTranslation();

  const titleMainSubPrefix = t('document_title1');
  const defaultTitle = `${String.fromCodePoint(9989)} ${t('document_title1')} | ${t(
    'document_title2'
  )} | ${t('document_title3')}`;
  const defaultDescription = t('document_description');
  const defaultKeywords = t('document_keywords');

  const titleWithCustomText = customTitle ? `${customTitle} | ${titleMainSubPrefix}` : defaultTitle;

  return (
    <Helmet>
      <title>{titleWithCustomText}</title>
      <meta
        name="description"
        content={customDescription || defaultDescription + ' | +38(050) 931-09-35'}
      />
      <meta name="keywords" content={customKeywords || defaultKeywords} />
    </Helmet>
  );
};
