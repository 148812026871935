import i18next from 'i18next';
import { useHistory, useLocation } from 'react-router';
import { DEFAULT_LANGUAGE, languages } from '../common/languages';
import { useEffect } from 'react';
import { StringHelper } from '../helpers/StringHelper';

export const useLocale = () => {
  const location = useLocation();
  const history = useHistory();

  const currentLangCode = i18next.language;

  const languagesCodeList = languages.map(({ value }) => value);

  const localeExistInPath = languagesCodeList.filter((lang) => location.pathname.includes(lang));
  const baseRouteUrl = localeExistInPath.length ? '/:locale' : '';

  useEffect(() => {
    if (!currentLangCode || localeExistInPath.length) return;

    if (currentLangCode !== DEFAULT_LANGUAGE) {
      history.push(`/${currentLangCode}${location.pathname}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentLangCode, localeExistInPath]);

  useEffect(() => {
    if (location.pathname.includes(DEFAULT_LANGUAGE)) {
      history.push(StringHelper.removeItemsFromString(location.pathname, languagesCodeList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, languagesCodeList]);

  return {
    baseRouteUrl,
    currentLangCode,
    prefixLocale: currentLangCode !== DEFAULT_LANGUAGE ? `/${currentLangCode}` : ''
  };
};
