import React from 'react';
import Insurance from '../../assets/images/photo-insurance.jpg';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">{t('page_insurance_title_text')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>{t('page_insurance_text1')}</p>
          <p>{t('page_insurance_text2')}</p>
          <ul>
            <li>{t('page_insurance_list_text1')}</li>
            <li>{t('page_insurance_list_text2')}</li>
            <li>{t('page_insurance_list_text3')}</li>
            <li>{t('page_insurance_list_text4')}</li>
            <li>{t('page_insurance_list_text5')}</li>
            <li>{t('page_insurance_list_text6')}</li>
          </ul>
          <p>{t('page_insurance_text3')}</p>
          <p>{t('page_insurance_text4')}</p>
        </div>
        <div className="col-md-6">
          <div className="singleImg">
            <img src={Insurance} alt="License" />
          </div>
        </div>
      </div>
    </div>
  );
};
