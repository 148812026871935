import React from 'react';

import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';
import InfoPageForm from '../../components/forms/InfoPageForm';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <SEO customTitle={t('footer_column_contacts')} />
      <h2 className="thinTitle">{t('page_contacts_title_text')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <div className="singleBlock">
            <h4>{t('page_contacts_title_text1')}</h4>
            <p>{t('page_contacts_text1')}</p>
            <p>+38 (050) 222-71-62</p>
            <p>+38 (057) 340-40-03</p>
            <p>Lenam_2005@ukr.net</p>
          </div>
          <div className="singleBlock">
            <h4>{t('page_contacts_title_text2')}</h4>
            <p>{t('page_contacts_text2')}</p>
            <p>+38 (057) 340-40-03</p>
            <p>+38 (050) 632-53-04</p>
            <p>Slavelena17@gmail.com</p>
          </div>
          <div className="singleBlock">
            <h4>{t('page_contacts_title_text3')}</h4>
            <p>{t('page_contacts_text3')}</p>
            <p>+38 (057) 340-55-88</p>
            <p>+38 (050) 343-37-10</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="formWrapper align-left noLabelForm">
            <div className="formWrapper-title">{t('page_contacts_title_text4')}</div>
            <InfoPageForm />
          </div>
        </div>
      </div>
    </div>
  );
};
