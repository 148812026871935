import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { setBasketProductsSum } from '../../hooks/basketHooks';
import { RiErrorWarningFill } from 'react-icons/all';
import { useTranslation } from 'react-i18next';
import TableMarkup from './TableMarkup';

export default () => {
  const { t } = useTranslation();

  const basketProducts = useSelector((state: RootState) => state.products.basketProducts);
  const pharmacies = useSelector((state: RootState) => state.global.pharmacies);
  const basketProductsSum = useMemo(() => setBasketProductsSum(basketProducts), [basketProducts]);

  const productsSplittedByPharmacy = useMemo(
    () =>
      basketProducts.reduce((acc, cur) => {
        if (!acc[cur.pharmacy.id]) {
          acc[cur.pharmacy.id] = [];
        }
        acc[cur.pharmacy.id].push(cur);
        return acc;
      }, {}),
    []
  );

  const showWarning = () => {
    const valueArr = basketProducts.map(function (item) {
      return item.id;
    });
    return valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });
  };

  return (
    <div className="scrollableTable">
      <table>
        <thead>
          <tr>
            <th>{t('page_create_order_text1')}</th>
            <th>{t('page_create_order_text2')}</th>
            <th>{t('page_create_order_text3')}</th>
            <th>{t('page_create_order_text4')}</th>
            <th>{t('page_create_order_text5')}</th>
            <th>{t('page_create_order_text7')}</th>
            <th>{t('page_create_order_text8')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(productsSplittedByPharmacy).map((pharmacyId) => (
            <>
              <tr>
                <th colSpan={9} scope="colgroup">
                  {pharmacies.find(({ id }) => id === Number(pharmacyId))?.name || ''}
                </th>
              </tr>
              {productsSplittedByPharmacy[pharmacyId].map((product) => (
                <TableMarkup key={product.id + product.pharmacy.id} product={product} />
              ))}
            </>
          ))}
          <tr>
            <td className="text-right total" colSpan={8}>
              {t('page_create_order_text9')} <strong>{basketProductsSum.toFixed(2)} грн</strong>
            </td>
          </tr>
        </tbody>
      </table>
      {showWarning() ? (
        <div className="order-creation-footer-attention">
          <RiErrorWarningFill size={50} />
          <p>{t('page_create_order_duplicates_attention')}</p>
        </div>
      ) : null}
    </div>
  );
};
