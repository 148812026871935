import { LoginResponseType, RegisterResponseType, UserType } from '../responseTypes';
import { LOGOUT, SET_LOGIN_DATA } from '../actionTypes';

export function successLogin(user: UserType | RegisterResponseType) {
  return {
    type: SET_LOGIN_DATA,
    payload: user
  };
}

export function successLogout() {
  return {
    type: LOGOUT
  };
}

export function saveDataToLS(result: LoginResponseType) {
  return () => {
    localStorage.setItem('accessToken', result.accessToken);
    localStorage.setItem('refreshToken', result.refreshToken);
    localStorage.setItem('user', JSON.stringify(result.user));
    localStorage.setItem('isAuth', 'true');
  };
}
