import React from 'react';
import useUnitNamesRedactor from '../../hooks/useUnitNamesRedactor';

interface IProductUnitProps {
  id: number;
  name: string;
  selectedUnit: number;
  setSelectedUnit: (id: number) => void;
}

const ProductUnit = ({ id, name, selectedUnit, setSelectedUnit }: IProductUnitProps) => {
  const unitName = useUnitNamesRedactor(name);
  return (
    <>
      <input
        type="radio"
        id={`${id}-id`}
        checked={id === selectedUnit}
        onChange={() => setSelectedUnit(id)}
      />
      <label htmlFor={`${id}-id`} className="cro">
        <span className="fix" />
        <span className="fix2" />
      </label>
      <label className="unit-name" htmlFor={`${id}-id`}>
        {unitName}
      </label>
    </>
  );
};
export default ProductUnit;
