import {
  GET_USER_ORDERS,
  SET_PHARMACIES,
  SET_BANNERS,
  SET_PROMOTIONS,
  SET_SERVER_ERROR
} from '../actionTypes';

const initialState = {
  userOrders: [],
  pharmacies: localStorage.getItem('pharmacies')
    ? JSON.parse(localStorage.getItem('pharmacies') || '')
    : [],
  banners: [],
  promotions: [],
  serverError: false
};

export default function globalReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_USER_ORDERS:
      return {
        ...state,
        userOrders: action.payload
      };
    case SET_PHARMACIES:
      return {
        ...state,
        pharmacies: action.payload
      };
    case SET_BANNERS:
      return {
        ...state,
        banners: action.payload
      };
    case SET_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload
      };
    case SET_SERVER_ERROR:
      return {
        ...state,
        serverError: true
      };
    default:
      return state;
  }
}
