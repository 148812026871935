import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsByCategory } from '../../store/actions/products';
import { RootState } from '../../store/reducers/rootReducer';
import Spinner from '../../components/Spinner';
import ProductView from './ProductView';
import CategoriesSidebar from './CategoriesSidebar';
import { statuses } from '../../common/enums';
import { useHistory, useParams } from 'react-router-dom';
import { UriParamsCategoryIdType } from '../../store/responseTypes';
import { setActiveCategory } from '../../store/actions/categories';
import { useTranslation } from 'react-i18next';
import ErrorBoundaryWrapper from '../../components/ErrorBoundary';
import Pagination from '../../components/Pagination';
import { getPageParams, setPageParams } from '../../utils/QueryParams/PageParams';
import goTopSmooth from '../../utils/goTopSmooth/goTopSmooth';
import { SearchProductType } from '../../api/search/types';

export default () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { categoryId }: { categoryId?: string } = useParams<UriParamsCategoryIdType>();

  const categories = useSelector((state: RootState) => state.cats.categories);
  const activeCategory = useSelector((state: RootState) => state.cats.activeCategory);
  const status = useSelector((state: RootState) => state.products.status);
  const { data: products, count } = useSelector((state: RootState) => state.products.products);
  const [showElements, setShowElements] = useState(15);

  const [skip, setSkip] = useState(0);
  const [sortingType, setSortingType] = useState('externalId');
  const params = useMemo(
    () => ({
      take: showElements,
      skip,
      sort: sortingType,
      inStock: true
    }),
    [skip, sortingType, showElements]
  );

  const handlePageClick = (event: { selected: number }) => {
    setSkip(event.selected * showElements);
    const search = setPageParams(event.selected + 1);

    history.replace({
      pathname: `/products/${categoryId}`,
      search
    });
    goTopSmooth();
  };
  useEffect(() => {
    const storageCategoryIsEmpty = !activeCategory || activeCategory.id !== Number(categoryId);
    if (storageCategoryIsEmpty && categories.length) {
      dispatch(setActiveCategory(Number(categoryId)));
    }
  }, [categoryId, categories, activeCategory, dispatch]);

  useEffect(() => {
    // if (!activeCategory) return
    // if (activeCategory.id !== Number(categoryId))
    // setSelectedCategory(activeCategory.id);
    goTopSmooth();
  }, [activeCategory]);

  useEffect(() => {
    const page = getPageParams(history.location.search);

    setSkip(showElements * (page === -1 ? 0 : page));
  }, [showElements, activeCategory, history.location.search]);

  useEffect(() => {
    dispatch(fetchProductsByCategory(categoryId, params));
  }, [params, categoryId, dispatch]);

  return (
    <>
      {status === statuses.idle ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="row catalog">
          <div className="col-sm-3 desktop-categories categories-sidebar_wrapper">
            <CategoriesSidebar />
          </div>
          <div className="products col-sm-9">
            <h1>{activeCategory?.name}</h1>
            <div className="products-options">
              <div className="products-options-sort">
                <div className="products-options-select">
                  <label htmlFor="select_sort">{t('sorting_title')}:</label>
                  <select
                    value={sortingType}
                    onChange={(event) => setSortingType(event.target.value)}
                    name="select_sort"
                    id="select_sort"
                  >
                    <option value="externalId">{t('sorting_by_default_option')}</option>
                    <option value="name">{t('sorting_by_name_up_option')}</option>
                    <option value="-name">{t('sorting_by_name_down_option')}</option>
                    <option value="viewed">{t('sorting_by_popular_option')}</option>
                  </select>
                </div>
                <div className="products-options-select">
                  <label htmlFor="select_skip">{t('show_number_products_title')}:</label>
                  <select
                    value={showElements}
                    onChange={(event) => setShowElements(Number(event.target.value))}
                    name="select_skip"
                    id="select_skip"
                  >
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <ErrorBoundaryWrapper>
              <div className="row equal">
                {products?.map((product: SearchProductType) => (
                  <div key={product.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <ProductView product={product} />
                  </div>
                ))}
              </div>
            </ErrorBoundaryWrapper>
            <div className="products-pagination">
              <Pagination
                count={count}
                itemsPerPage={showElements}
                handlePageClick={handlePageClick}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
