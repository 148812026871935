import { FETCH_CATEGORIES_SUCCESS, SET_ACTIVE_CATEGORY } from '../actionTypes';
import { Category } from '../responseTypes';

interface CategoryState {
  categories: Category[];
  activeCategory: Category | null;
}

const initialState: CategoryState = {
  categories: [],
  activeCategory: null
};

export default function categoriesReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories
      };
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.category
      };
    default:
      return state;
  }
}
