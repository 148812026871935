import React, { useEffect } from 'react';
import { ArticlePreview } from './ArticlePreview';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { fetchPostsByLocale } from '../../store/actions/blog';
import { useLocale } from '../../hooks/useLocale';
import Spinner from '../../components/Spinner';

const Articles = () => {
  const { currentLangCode } = useLocale();

  const dispatch = useDispatch();
  const posts = useSelector((state: RootState) => state.blog.posts);

  useEffect(() => {
    dispatch(fetchPostsByLocale(currentLangCode));
  }, [dispatch, currentLangCode]);

  if (!posts.length) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      {posts.map((item, index) => (
        <ArticlePreview key={index} {...item} />
      ))}
    </>
  );
};

export default Articles;
