import { Modal } from '@material-ui/core';
import React from 'react';
import ErrorIcon from '../assets/images/errorIcon.svg';
import { useTranslation } from 'react-i18next';

export default ({ open }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <div className="modal">
        <div className="modal-title">
          <h3>{t('server_error_title')}</h3>
        </div>
        <div className="modal-body">
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                marginBottom: 30
              }}
            >
              <img
                style={{
                  width: 100,
                  height: 100
                }}
                src={ErrorIcon}
              />
            </div>
            <div className="form-wrapper" style={{ fontSize: 14 }}>
              {t('server_error_text')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
