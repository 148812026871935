import React from 'react';
import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';
import { Post } from '../../store/responseTypes';
import { useTranslation } from 'react-i18next';

const ArticleItem = ({ isImageRightSide, image, title, description, slug }: Post) => {
  const { t } = useTranslation();

  if (!image) {
    return (
      <div className="article articleItem articleItem-withoutImage">
        <div className="articleItem-content-wrapper pr">
          <NavLink to={`/post/${slug}`}>
            <h2 className="article-title extended">{title}</h2>
          </NavLink>
          <div className="decoration-line" />

          <NavLink to={`/post/${slug}`}>
            <span>{t('blog_read_more')}</span>
          </NavLink>
        </div>
        <div className="articleItem-content-wrapper pl">
          <p className="article-description">{description}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx('article articleItem', isImageRightSide && 'articleItem-reverse')}>
      <div className={clsx('articleItem-content-wrapper', !isImageRightSide ? 'pr' : 'pl')}>
        <div className={clsx('articleItem-image-wrapper', isImageRightSide && 'reverse')}>
          <div className={clsx('articleItem-image-bg', isImageRightSide && 'reverse')} />
          <img className={`articleItem-image `} src={image} alt="articleItem image" />
        </div>
      </div>
      <div className={clsx('articleItem-content-wrapper', !isImageRightSide ? 'pl' : 'pr')}>
        <NavLink to={`/post/${slug}`}>
          <h1 className="article-title">{title}</h1>
        </NavLink>

        <p className="article-description">{description}</p>
        <NavLink to={`/post/${slug}`}>
          <span>{t('blog_read_more')}</span>
        </NavLink>
      </div>
    </div>
  );
};

export default ArticleItem;
