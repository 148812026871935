import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { fetchUserOrders } from '../../store/actions/global';
import { useDispatch } from 'react-redux';
import AuthValidation from '../../hoc/AuthValidation';
import Orders from './Orders';
import UserData from './UserData';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';
import LogOut from './LogOut';
import Wishlist from '../Wishlist/Wishlist';
import { useLocale } from '../../hooks/useLocale';

export default () => {
  const { t } = useTranslation();
  const { prefixLocale } = useLocale();

  const tabs = [
    {
      id: 1,
      path: '/profile',
      title: t('page_profile_bottom1'),
      isActive: false,
      isExact: true,

      component: <Orders key={1} />
    },
    {
      id: 2,
      path: '/profile/account',
      title: t('page_profile_bottom2'),
      isActive: false,
      isExact: false,

      component: <UserData key={2} />
    },
    {
      id: 3,
      path: '/profile/wishlist',
      title: t('page_profile_bottom3'),
      isActive: false,
      isExact: false,

      component: <Wishlist />
    },
    {
      id: 4,
      path: '/profile/logout',
      title: t('page_profile_bottom4'),
      isActive: false,
      isExact: false,

      component: <LogOut />
    }
  ];
  const dispatch = useDispatch();
  const { pathname }: { pathname: string } = useLocation();
  const path = useMemo(() => pathname.replace(prefixLocale, ''), [pathname]);

  useEffect(() => {
    dispatch(fetchUserOrders());
  }, [dispatch]);

  const renderTabsHeadings = () =>
    tabs.map((tab) => (
      <NavLink
        to={`${prefixLocale}${tab.path}`}
        key={tab.id}
        activeClassName="active"
        className="tabs-btn"
        exact={tab.isExact}
      >
        {tab.title}
      </NavLink>
    ));

  const renderTabsContent = () =>
    tabs.map((tab) => {
      if (tab.path !== path) {
        return null;
      }
      return tab.component;
    });
  return (
    <AuthValidation>
      <div>
        <SEO customTitle={t('header_nav_personal_cabinet')} />
        <div className="tabs">{renderTabsHeadings()}</div>
        <div className="tabs-content">
          <div className="tabs-content-block">{renderTabsContent()}</div>
        </div>
      </div>
    </AuthValidation>
  );
};
