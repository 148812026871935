import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { successLogout } from '../../store/actions/authorization';
import { resetWishlistProducts } from '../../store/actions/wishlist';

const LogOut = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(successLogout());
    dispatch(resetWishlistProducts());

    toast.info(t('page_profile_text4'));

    localStorage.clear();
  }, [dispatch, t]);

  return <Redirect to={'/'} />;
};
export default LogOut;
