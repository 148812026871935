import React from 'react';
import Photo from '../../assets/images/photo-discount.jpg';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">{t('page_discount_programs_title_text1')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>
            <strong>{t('page_discount_programs_title_text2')}</strong>
          </p>
          <p>{t('page_discount_programs_text1')}</p>
          <p>{t('page_discount_programs_text2')}</p>
          <ul>
            <li>{t('page_discount_programs_list_text1')}</li>
            <li>{t('page_discount_programs_list_text2')}</li>
            <li>{t('page_discount_programs_list_text3')}</li>
          </ul>
          <p>{t('page_discount_programs_text3')}</p>
          <img src={Photo} alt="License" />
        </div>
        <div className="col-md-6">
          <p>
            <strong>{t('page_discount_programs_title_text3')}</strong>
          </p>
          <p>{t('page_discount_programs_text4')}</p>
          <p>{t('page_discount_programs_text5')}</p>
          <p>
            <strong>{t('page_discount_programs_title_text4')}</strong>
          </p>
          <ul>
            <li>{t('page_discount_programs_list_text4')}</li>
            <li>{t('page_discount_programs_list_text5')}</li>
            <li>{t('page_discount_programs_list_text6')}</li>
          </ul>
          <p>{t('page_discount_programs_text6')}</p>
          <p>
            <strong>{t('page_discount_programs_title_text5')}</strong>
          </p>
          <p>{t('page_discount_programs_text7')}</p>
          <ul>
            <li>{t('page_discount_programs_list_text7')}</li>
            <li>{t('page_discount_programs_list_text8')}</li>
          </ul>
          <p>
            <strong>{t('page_discount_programs_title_text6')}</strong>
          </p>
          <p>{t('page_discount_programs_text8')}</p>
          <p>{t('page_discount_programs_text9')}</p>
          <p>{t('page_discount_programs_text10')}</p>
          <ul>
            <li>{t('page_discount_programs_list_text9')}</li>
            <li>{t('page_discount_programs_list_text10')}</li>
            <li>{t('page_discount_programs_list_text11')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
