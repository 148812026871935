import { useRef } from 'react';
import useGetStoreData from './useGetStoreData';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { TIMEOUT_DELAY } from '../common/common';
import { useDispatch } from 'react-redux';
import {
  addProductToWishlist,
  fetchWishlist,
  removeProductToWishlist
} from '../store/actions/wishlist';

export type WishlistClickHandlerProps = {
  id: number;
  isWishlist: boolean;
};

const useWishlistHandler = () => {
  const user = useGetStoreData();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const clickHandler = async ({ id, isWishlist }: WishlistClickHandlerProps) => {
    if (!user) {
      return toast.error(t('not_auth_error_text'));
    }

    if (delayTimeoutRef.current) {
      clearTimeout(delayTimeoutRef.current);
    }

    delayTimeoutRef.current = setTimeout(async () => {
      if (isWishlist) {
        await dispatch(removeProductToWishlist(id));
        await dispatch(fetchWishlist());
      } else {
        await dispatch(addProductToWishlist(id));
      }
    }, TIMEOUT_DELAY);
  };
  return { clickHandler };
};

export default useWishlistHandler;
