import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import { AxiosError } from 'axios';

export const handleError = (
  err: Error | string | AxiosError,
  level: SeverityLevel = 'error',
  tags: Record<string, string> = {}
) => {
  if (err instanceof AxiosError) {
    const statusCode = err?.response?.status || 9999;

    err.message = `Error requesting ${
      err.config ? err.config.url : 'no-url-in-config'
    }: (status: ${statusCode}) ${err.message}`;

    Sentry.captureException(err, (scope) => {
      scope.setLevel(level);
      scope.setTags(tags);
      if (err.config) {
        scope.setTag('request-url', err.config.url);
      }
      return scope;
    });
  }

  Sentry.captureException(err, (scope) => {
    scope.setLevel(level);
    scope.setTags(tags);
    return scope;
  });
};

export default handleError;
