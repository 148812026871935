import * as Scroll from 'react-scroll';

const scroll = Scroll.animateScroll;
const goTopSmooth = () => {
  scroll.scrollToTop({
    duration: 1000,
    delay: 100,
    smooth: true
  });
};
export default goTopSmooth;
