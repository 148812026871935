const getPageParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  return Number(searchParams.get('page')) - 1;
};

const setPageParams = (value: number) => {
  if (value === 1) return;
  const searchParams = new URLSearchParams();
  searchParams.set('page', value.toString());
  return searchParams.toString();
};

export { getPageParams, setPageParams };
