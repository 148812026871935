import React from 'react';
import Rent from '../../assets/images/rent.jpg';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">{t('page_rent_title_text')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>{t('page_rent_text1')}</p>
          <p>{t('page_rent_text2')}</p>
          <p>
            <strong>Lenam_2005@ukr.net</strong>
          </p>
          <p>{t('page_rent_text3')}</p>
        </div>
        <div className="col-md-6">
          <div className="singleImg">
            <img src={Rent} alt="License" />
          </div>
        </div>
      </div>
    </div>
  );
};
