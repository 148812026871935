import * as React from 'react';
import AuthValidation from '../../hoc/AuthValidation';
import ProductView from '../Catalog/ProductView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { useEffect } from 'react';
import { fetchWishlist } from '../../store/actions/wishlist';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => state.wishlist.products);

  useEffect(() => {
    dispatch(fetchWishlist());
  }, [dispatch]);

  return (
    <AuthValidation>
      <div className="page-wrapper">
        <h2 className="thinTitle">{t('page_profile_title_text3')}</h2>
        <div className="row" style={{ marginTop: 30 }}>
          {products && products.length ? (
            products.map((product: any) => (
              <div key={product.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <ProductView product={product} isWishlist={true} />
              </div>
            ))
          ) : (
            <div className="col-xs-12">
              <h3 className="text-center" style={{ marginTop: 30 }}>
                {t('page_profile_text2')}
              </h3>
            </div>
          )}
        </div>
      </div>
    </AuthValidation>
  );
};
