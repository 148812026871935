import React from 'react';
import ReactPaginate from 'react-paginate';
import { getPageParams } from '../utils/QueryParams/PageParams';
import { useHistory } from 'react-router-dom';

interface IEvent {
  selected: number;
}
interface IPagination {
  count: number;
  itemsPerPage: number;
  handlePageClick: (e: IEvent) => void;
}

const Pagination = ({ count, itemsPerPage, handlePageClick }: IPagination) => {
  const pageCount = Math.ceil(count / itemsPerPage);
  const history = useHistory();
  const page = getPageParams(history.location.search);

  if (pageCount <= 1) return null;

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        onPageChange={handlePageClick}
        forcePage={page !== -1 ? page : 0}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        activeLinkClassName="active"
        activeClassName="active"
        className="pagination-container"
        pageClassName="pagination-item"
        pageLinkClassName="pagination-link"
        nextClassName="pagination-item"
        previousClassName="pagination-item"
        nextLinkClassName="pagination-link"
        previousLinkClassName="pagination-link"
        disabledClassName={'disabled'}
      />
    </>
  );
};

export default Pagination;
