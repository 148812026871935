import axios, { AxiosError } from 'axios';
import { LOCALSTORAGE_KEYS } from '../constants/constants';
import { getRefreshToken, translateErrorMessage } from './Authorization';
import handleError from '../utils/integrations/sentry';

const baseApiUrl = process.env.REACT_APP_API_URI;

export const ApiClient = axios.create({
  baseURL: baseApiUrl,
  paramsSerializer: {
    encode: (param) => encodeURIComponent(param)
  }
});

ApiClient.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      config.headers.Authorization = 'Bearer ' + accessToken;
    }
    if (config.params?.refreshToken) {
      config.headers.Authorization = 'Bearer ' + config.params.refreshToken;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ApiClient.interceptors.response.use(
  ({ data }) => data,
  async (error) => {
    const originalRequest = error.config;
    const oldAccessToken = localStorage.getItem('accessToken');
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      oldAccessToken
    ) {
      originalRequest._retry = true;
      try {
        const accessToken = await getRefreshToken();

        if (accessToken) {
          originalRequest.headers.Authorization = 'Bearer ' + accessToken;
        } else {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          localStorage.removeItem('isAuth');
          localStorage.removeItem('password');
          window.location.href = '/authorization';
        }
        const { data } = await axios(originalRequest);
        return data;
      } catch (error) {
        handleError(error as AxiosError);
      }
    }
    if (error.response && error.response.status === 403) {
      localStorage.clear();
    }
    const genuineErrorMessage = error.response.data.message;
    error.response.data.message = translateErrorMessage(error.response.data.statusCode);
    handleError(error as AxiosError, 'error', {
      genuineErrorMessage
    });
    return Promise.reject(error);
  }
);
