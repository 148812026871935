import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { DEFAULT_LANGUAGE } from './common/languages';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['ru', 'ua'],
    fallbackLng: DEFAULT_LANGUAGE,
    detection: {
      order: ['path']
      // caches: ['localStorage', 'cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    },
    react: { useSuspense: false }
  });
export default i18n;
