import React from 'react';
import Jobs from '../../assets/images/jobs.jpg';
import Partner1 from '../../assets/images/partner1.png';

import { useTranslation } from 'react-i18next';
import InfoPageForm from '../../components/forms/InfoPageForm';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">{t('page_jobs_title_text')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>{t('page_jobs_text1')}</p>
          <p>{t('page_jobs_text2')}</p>
          <p>{t('page_jobs_text3')}</p>
          <ul>
            <li>{t('page_jobs_list_text1')}</li>
            <li>{t('page_jobs_list_text2')}</li>
            <li>{t('page_jobs_list_text3')}</li>
            <li>{t('page_jobs_list_text4')}</li>
            <li>{t('page_jobs_list_text5')}</li>
          </ul>
          <p>
            {t('page_jobs_text4')} <br />
            {t('page_jobs_text5')} 340-40-03, 050-222-71-62
          </p>
          <div className="formWrapper align-left noLabelForm" style={{ marginTop: 50 }}>
            <div className="formWrapper-title">{t('page_contacts_title_text4')}</div>
            <InfoPageForm />
          </div>
        </div>
        <div className="col-md-6">
          <div className="singleImg">
            <img src={Jobs} alt="License" />
          </div>
          <div className="partnersBlock">
            <a href="https://nuph.edu.ua/" target="_blank" rel="noreferrer">
              <img src={Partner1} alt="Partner" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
