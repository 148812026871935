import { Post } from '../responseTypes';
import { SET_BLOG_POSTS, SET_BLOG_LOADING } from '../actionTypes';

interface BlogState {
  posts: Post[];
  loading: boolean;
}

export const initialPostsState: BlogState = {
  posts: [],
  loading: false
};

export default function blogReducer(state = initialPostsState, action: any) {
  switch (action.type) {
    case SET_BLOG_POSTS:
      return {
        ...state,
        posts: action.payload
      };
    case SET_BLOG_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
