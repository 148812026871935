import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../store/reducers/rootReducer';
import { fetchCategories } from '../store/actions/categories';

import { Category } from '../store/responseTypes';
import { FaSignInAlt } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import HeadSearch from './HeadSearch';
import { setBasketProductsSum } from '../hooks/basketHooks';
import { NavLink } from 'react-router-dom';
import { BiBasket, GiHamburgerMenu } from 'react-icons/all';
import { PrivateRoute } from './PrivateRoute';
import CategoriesSidebar from '../views/Catalog/CategoriesSidebar';
import CallbackPopup from './CallbackPopup';
import { fetchWishlist } from '../store/actions/wishlist';
import { fetchPharmacies } from '../store/actions/global';
import ServerErrorPopup from './ServerErrorPopup';
import { DEFAULT_LANGUAGE, languages } from '../common/languages';
import { SelectDropdown } from './SelectDropdown';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/useLocale';
import { slide as Menu } from 'react-burger-menu';
import { HeaderNav } from './HeaderNav';
import { getNameByLocale } from '../utils/getNameByLocale';

const Header = (props: any) => {
  const { t } = useTranslation();
  const { currentLangCode } = useLocale();

  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);
  const [showMobileCategories, setShowMobileCategories] = useState(false);
  const [callbackPopupShow, setCallbackPopupShow] = useState(false);
  const [isMobileWidth, setIsMobileWidth] = useState(window.innerWidth <= 1199);
  const categories = useSelector((state: RootState) => state.cats.categories);
  const basketProducts = useSelector((state: RootState) => state.products.basketProducts);
  const movedHeaderClasses = ['flex-container', 'align-center', 'scrolled', 'container'];

  const isAuthFromStore = useSelector((state: RootState) => state.auth.isAuth);
  const serverErrorPopupShow = useSelector((state: RootState) => state.global.serverError);
  const isAuthFromLS = localStorage.getItem('isAuth');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScreenWidth);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScreenWidth);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchCategories());
    if (isAuthFromStore || isAuthFromLS) dispatch(fetchWishlist());
  }, [dispatch, isAuthFromStore, isAuthFromLS]);

  useEffect(() => {
    dispatch(fetchPharmacies());
  }, [dispatch]);

  if (scrolled) {
    movedHeaderClasses.push('active');
  }

  const basketProductsSum = useMemo(() => setBasketProductsSum(basketProducts), [basketProducts]);
  const basketProductsCount = basketProducts.reduce((acc: number, item: any) => {
    acc += Number(item.count);
    return acc;
  }, 0);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 75) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleScreenWidth = () => {
    if (window.innerWidth <= 1199) {
      setIsMobileWidth(true);
    } else {
      setIsMobileWidth(false);
    }
  };
  const toggleShowMobileCategories = () => {
    setShowMobileCategories((state) => !state);
  };

  const renderCategories = (categories: Array<Category>) =>
    categories.map((category, index) => {
      const name = getNameByLocale(category, currentLangCode);
      return (
        <li key={index}>
          <NavLink to={`/products/${category.id}`}>
            <img src={`${window.location.origin}/images/${category.icon}`} alt={name} />
            <span>{name}</span>
          </NavLink>
        </li>
      );
    });

  const mainHeaderInfo = () => {
    const LogoBlock = () => (
      <div className="logo">
        <NavLink to={'/'}>
          <img
            src={`/images/${currentLangCode}/logo.svg`}
            title={t('page_pharmacy_map_title_text1')}
            alt={t('page_pharmacy_map_title_text1')}
          />
        </NavLink>
      </div>
    );
    const SearchBlock = () => <HeadSearch />;
    const PhonesBlock = () => (
      <div className="phones flex-container">
        <FaMobileAlt size={45} />
        <div className="phones-container">
          <a href="tel:0509310935">(050) 931-09-35</a>
          <a href="tel:0975270340">(097) 527-03-40</a>
        </div>
      </div>
    );
    const CallBackBlock = () => (
      <div className="feedback" onClick={() => setCallbackPopupShow(true)}>
        {t('header_callback_btn')}
      </div>
    );
    const BasketBlock = () => (
      <div className="basket-btn" onClick={() => props.basketOpen()}>
        <div className="icon">
          <BiBasket size={25} />
        </div>
        {basketProductsCount} {t('header_basket_products')} - {basketProductsSum.toFixed(2)} грн
      </div>
    );
    if (isMobileWidth) {
      return (
        <div className="mobile">
          <div className="flex-container mb-20">
            <LogoBlock />
            <SearchBlock />
          </div>
          <div className="flex-container">
            <PhonesBlock />
            <CallBackBlock />
            <BasketBlock />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <LogoBlock />
          <SearchBlock />
          <PhonesBlock />
          <CallBackBlock />
          <BasketBlock />
        </>
      );
    }
  };

  return (
    <>
      {/*{*/}
      {/*	openTestWarning && <TestMessage close={() => setOpenTestWarning(false)} />*/}
      {/*}*/}
      <div className="nav flex-container">
        <div className="container flex-container">
          <HeaderNav />
          <div className="flex-container">
            <div style={{ marginRight: 50 }}>
              <SelectDropdown
                options={languages}
                selected={languages.find((item) => item.value === currentLangCode)}
                placeholder={t('header_switch_lang_placeholder')}
                onClick={(selectedOption) =>
                  (window.location.href =
                    selectedOption?.value === DEFAULT_LANGUAGE ? '/' : `/${selectedOption?.value}`)
                }
              />
            </div>
            <PrivateRoute classes="login" to={'/profile'}>
              <FaSignInAlt size={20} />
              <span>{t('header_nav_personal_cabinet')}</span>
            </PrivateRoute>
          </div>
        </div>
      </div>
      <header>
        <div className="container header-wrapper">
          {scrolled ? <div className={movedHeaderClasses.join(' ')}>{mainHeaderInfo()}</div> : null}
          <div className="flex-container align-center">{mainHeaderInfo()}</div>
          <div className="index-info">
            <div className="closeableContainer">
              <p>
                {t('header_working_hours')}: <br />
                {t('header_working_hours_from')} 9:00 до 19:00 (пн-пт),{' '}
                {t('header_working_hours_from')} 9:00 до 15:00 (сб-
                {t('header_working_hours_sunday')}).
              </p>
            </div>
          </div>
          {categories.length ? (
            <div className="categoriesList" id="outer-container">
              <div className="mobileCategoriesHidden">
                <div className="categoriesTitle" onClick={toggleShowMobileCategories}>
                  <h3>Категории</h3>
                  <GiHamburgerMenu size={22} />
                </div>

                <Menu
                  customBurgerIcon={false}
                  isOpen={showMobileCategories}
                  onStateChange={(s: { isOpen: boolean }) => setShowMobileCategories(s.isOpen)}
                  pageWrapId={'page-wrap'}
                  outerContainerId={'outer-container'}
                >
                  <CategoriesSidebar onCloseMenu={() => setShowMobileCategories(false)} />
                </Menu>
              </div>
              <ul className="flex-container" id="page-wrap">
                {renderCategories(categories)}
              </ul>
            </div>
          ) : null}
          <div className="warn">
            <p>{t('header_price_warning')}</p>
            <p>{t('header_price_warning_additional')}</p>
          </div>
        </div>
      </header>
      <CallbackPopup open={callbackPopupShow} close={() => setCallbackPopupShow(false)} />
      <ServerErrorPopup open={serverErrorPopupShow} />
    </>
  );
};

export default Header;
