import {
  GET_USER_ORDERS,
  SET_BANNERS,
  SET_PHARMACIES,
  SET_PROMOTIONS,
  SET_SERVER_ERROR
} from '../actionTypes';
import { Dispatch } from 'redux';
import { orderFields } from '../constansts';
import { ApiGetResponse, PharmacyType } from '../responseTypes';
import * as _ from 'lodash';
import { ApiClient } from '../../api/ApiClient';
import { BannersResponseType } from '../types/global';
import axios from 'axios';
import { toast } from 'react-toastify';

export function fetchUserOrders() {
  return async (dispatch: Dispatch) => {
    try {
      const params = {
        fields: orderFields,
        embed: 'products,products.product,products.unit,products.pharmacy'
      };
      const response = await ApiClient.get('/users/me/orders', { params });

      dispatch(fetchUserOrdersSuccess(response.data));
    } catch (error) {
      axios.isAxiosError(error) && toast.error(error.response?.data.message);
    }
  };
}

export function fetchPharmacies() {
  return async (dispatch) => {
    const params = {
      take: 50
    };
    const response: ApiGetResponse<PharmacyType> = await ApiClient.get('/pharmacies', { params });
    dispatch(
      fetchPharmaciesSuccess(
        _.orderBy(
          response.data.filter((p) => p.active),
          ['index'],
          ['asc']
        )
      )
    );
  };
}

export function fetchBanners() {
  return async (dispatch) => {
    const params = {
      take: 100,
      embed: 'images',
      fields: 'id,title,images.id,images.filename,images.uri,images.link'
    };
    const response: BannersResponseType = await ApiClient.get('/storage/banners', { params });
    dispatch(fetchBannersSuccess(response.data));
  };
}

export function fetchPromotions() {
  return async (dispatch) => {
    const params = {
      embed: 'products,products.images,products.pharmacies',
      selectAll: true
    };
    const response = await ApiClient.get('/storage/promotions', { params });
    dispatch(fetchPromotionsSuccess(response.data));
  };
}

export function fetchPharmaciesSuccess(items) {
  localStorage.setItem('pharmacies', JSON.stringify(items));
  return {
    type: SET_PHARMACIES,
    payload: items
  };
}

export function fetchUserOrdersSuccess(orders) {
  return {
    type: GET_USER_ORDERS,
    payload: orders
  };
}

export function fetchBannersSuccess(value) {
  return {
    type: SET_BANNERS,
    payload: value
  };
}

export function fetchPromotionsSuccess(value) {
  return {
    type: SET_PROMOTIONS,
    payload: value
  };
}

export function setServerError() {
  return {
    type: SET_SERVER_ERROR
  };
}
