export const languages = [
  {
    value: 'ru',
    label: 'РУС'
  },
  {
    value: 'ua',
    label: 'УКР'
  }
];

export const DEFAULT_LANGUAGE = 'ua';
