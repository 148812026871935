import React, { useRef } from 'react';
import InputComponent from '../InputComponent';
import * as Yup from 'yup';
import { VALIDATION } from '../../constants/validation';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { TIMEOUT_DELAY } from '../../common/common';

const FormPasswordSchema = Yup.object().shape({
  oldPassword: VALIDATION.password,
  password: VALIDATION.password,
  repeatPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')])
});

const FormPassword = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      repeatPassword: ''
    },
    validationSchema: FormPasswordSchema,
    onSubmit: (values) => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      delayTimeoutRef.current = setTimeout(async () => await handleSubmit(values), TIMEOUT_DELAY);
    }
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <InputComponent
        label={t('page_profile_entry_field1')}
        onChange={formik.handleChange}
        value={formik.values.oldPassword}
        error={Boolean(formik.errors.oldPassword && formik.touched.oldPassword)}
        errorText={t('form_input_password_valid_msg')}
        type="password"
        name="oldPassword"
        isRequired
        onBlur={formik.handleBlur}
      />
      <InputComponent
        value={formik.values.password}
        onChange={formik.handleChange}
        label={t('page_profile_entry_field2')}
        error={Boolean(formik.errors.password && formik.touched.password)}
        errorText={t('form_input_password_valid_msg')}
        type="password"
        name="password"
        isRequired
        onBlur={formik.handleBlur}
      />
      <InputComponent
        value={formik.values.repeatPassword}
        onChange={formik.handleChange}
        label={t('form_input_password_repeat')}
        error={Boolean(formik.errors.repeatPassword && formik.touched.repeatPassword)}
        errorText={t('form_input_password_repeat_valid_msg')}
        type="password"
        name="repeatPassword"
        isRequired
        onBlur={formik.handleBlur}
      />
      <div className="flex-container" style={{ justifyContent: 'flex-end' }}>
        <button type="submit" className="btn">
          {t('page_profile_save_buttom')}
        </button>
      </div>
    </form>
  );
};

export default FormPassword;
