import { Category } from '../responseTypes';
import { FETCH_CATEGORIES_SUCCESS, SET_ACTIVE_CATEGORY } from '../actionTypes';
import { setServerError } from './global';
import { Dispatch } from 'redux';
import { ApiClient } from '../../api/ApiClient';
import handleError from '../../utils/integrations/sentry';
import { AxiosError } from 'axios';

export function fetchCategories() {
  return async (dispatch: Dispatch) => {
    try {
      const params = {
        fields: 'id,name,icon,externalId,children.id,children.name,children.icon',
        embed: 'children',
        take: 10,
        sort: 'externalId',
        'parentId[equal]': 'null',
        'active[equal]': true
      };
      const response = await ApiClient.get('/categories', { params });

      dispatch(fetchCategoriesSuccess(response.data));
    } catch (error) {
      handleError(error as AxiosError, 'fatal');
      dispatch(setServerError());
    }
  };
}

export function fetchCategoriesSuccess(categories) {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    categories
  };
}

export function setActiveCategory(categoryId?: number) {
  return async (dispatch: any, getState) => {
    dispatch(closeAllCategories());
    const categories: Category[] = getState().cats.categories;
    const category = categories.find((item: Category) => item.id === categoryId);
    if (category) {
      category.open = true;
      dispatch(setActiveCategorySuccess(category));
    } else {
      const subCategory = categories.reduce((acc: Category[], cat) => {
        const category = cat.children?.find((item) => item.id === categoryId);
        if (category) acc.push(category);
        return acc;
      }, []);
      dispatch(setActiveCategorySuccess(subCategory[0]));
    }
  };
}

export function closeAllCategories() {
  return (dispatch: Dispatch, getState) => {
    const newCategories = getState().cats.categories.map((item) => ({
      ...item,
      open: false
    }));
    dispatch(fetchCategoriesSuccess(newCategories));
  };
}

export function setActiveCategorySuccess(category: Category) {
  return {
    type: SET_ACTIVE_CATEGORY,
    category
  };
}
