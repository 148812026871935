import { string } from 'yup';
import { phoneRegex } from '../regex';

export const VALIDATION = {
  text: string().min(3).max(32).required('Required'),
  phone: string()
    .matches(phoneRegex)
    .test('ukraine-format', 'This phone number is not Ukraine format', (value) => {
      if (typeof value === 'string') {
        const arr = value.split('');
        if (arr[0] === '+' && arr[1] === '3' && arr[2] === '8' && arr[3] === '0') {
          return true;
        } else {
          return !!(arr[0] === '3' && arr[1] === '8' && arr[2] === '0');
        }
      }
    })
    .required('Required'),

  email: string().email().required('Required'),
  emailOptional: string().email().optional(),
  password: string().lowercase().min(8).required('Required'),
  comment: string()
};
