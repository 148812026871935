import handleTitleLength from '../../utils/handleTitleLength/handleTitleLength';
import { SET_BASKET_PRODUCTS, SET_LOAD_BASKET } from '../actionTypes';
import { ProductAddToBasketType } from '../responseTypes';
import { setProductLoading, setUserPharmacy } from './products';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { getNameByLocale } from '../../utils/getNameByLocale';

function getProductsFromLocalStorage(): ProductAddToBasketType[] {
  const storageItem = localStorage.getItem('basketProducts'); // return stringify array
  return storageItem ? JSON.parse(storageItem) : [];
}

export function updateBasketItem(action) {
  return async (dispatch) => {
    dispatch(setBasketLoading(true));
    const products = getProductsFromLocalStorage();

    const newProducts = action(products);

    const productsPharmaciesIds = newProducts.map(({ pharmacy }) => pharmacy.id);
    dispatch(setUserPharmacy(productsPharmaciesIds));

    localStorage.setItem('basketProducts', JSON.stringify(newProducts));
    dispatch(successBasketChange(newProducts));

    await setTimeout(function () {
      dispatch(setBasketLoading(false));
    }, 100);
  };
}

export function addBasketItem(product: ProductAddToBasketType, locale: string) {
  return async (dispatch: Dispatch) => {
    dispatch(setProductLoading(true));
    const products = getProductsFromLocalStorage();
    const newProducts = products.filter((item) => item.id !== product.id);
    newProducts.push(product);
    localStorage.setItem('basketProducts', JSON.stringify(newProducts));
    dispatch(successBasketChange(newProducts));
    toast.success(
      i18next.t('product_warning_add_success', {
        name: handleTitleLength(getNameByLocale(product, locale)),
        interpolation: { escapeValue: false }
      })
    );
    await setTimeout(function () {
      dispatch(setProductLoading(false));
    }, 2000);
  };
}

export function changeBasketItemCount(product) {
  return (dispatch) => {
    const action = (products) => {
      const newProducts = products.filter((item) => item.id !== product.id);
      newProducts.push(product);
      return newProducts;
    };

    dispatch(updateBasketItem(action));
  };
}

export function removeBasketItem(product) {
  return (dispatch) => {
    const action = (products) => products.filter((item) => item.id !== product.id);

    dispatch(updateBasketItem(action));
  };
}

export function successBasketChange(products: any) {
  return {
    type: SET_BASKET_PRODUCTS,
    products
  };
}

export function setBasketLoading(loadBasket: any) {
  return {
    type: SET_LOAD_BASKET,
    loadBasket
  };
}
