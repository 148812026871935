import { RESET_WISHLIST, SET_WISHLIST, SET_WISHLIST_LOADING } from '../actionTypes';
import { ApiGetResponse, Product, WishlistResponseType } from '../responseTypes';
import i18next from 'i18next';
import { ApiClient } from '../../api/ApiClient';
import handleError from '../../utils/integrations/sentry';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export function fetchWishlist() {
  return async (dispatch) => {
    try {
      const params = {
        fields:
          'userId,productId,product.id,product.name,product.externalId,product.viewed,product.images.id,product.images.filename,product.images.uri',
        embed: 'product,product.images'
      };
      const result = await ApiClient.get<ApiGetResponse<Product>>('/users/me/wishlist', { params });

      localStorage.setItem('wishlist', JSON.stringify(result.data || []));
      dispatch(setWishlistProducts(result.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
      handleError(error as AxiosError);
    }
  };
}

export function addProductToWishlist(id) {
  return async (dispatch) => {
    dispatch(setWishlistLoading(true));

    try {
      await ApiClient.post<string | number, WishlistResponseType>(`users/me/wishlist/${id}`);

      toast.success(i18next.t('product_wishlist_add'));
      dispatch(setWishlistLoading(false));
    } catch (error) {
      dispatch(setWishlistLoading(false));

      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
      handleError(error as AxiosError);
    }
  };
}

export function removeProductToWishlist(id) {
  return async (dispatch) => {
    dispatch(setWishlistLoading(true));

    try {
      await ApiClient.delete(`/users/me/wishlist/${id}`);

      toast.success(i18next.t('product_wishlist_remove'));
      dispatch(setWishlistLoading(false));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
      handleError(error as AxiosError);
    }
  };
}

export function resetWishlistProducts() {
  return {
    type: RESET_WISHLIST
  };
}

export function setWishlistLoading(value) {
  return {
    type: SET_WISHLIST_LOADING,
    payload: value
  };
}

export function setWishlistProducts(data) {
  return {
    type: SET_WISHLIST,
    payload: data
  };
}
