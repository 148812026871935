import { useRef, useState } from 'react';
import handleError from '../utils/integrations/sentry';
import { getProductsBySearchValue } from '../api/search/search';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RootState } from '../store/reducers/rootReducer';
import {
  addRecentSearch,
  removeRecentSearch,
  setHeaderSearchProducts,
  setHeaderSearchValue
} from '../store/actions/search';
import { toast } from 'react-toastify';

export const useSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const searchValue = useSelector((state: RootState) => state.search.value);
  const products = useSelector((state: RootState) => state.search.products);
  const recentSearches = useSelector((state: RootState) => state.search.recentValues);

  const [isLoading, setLoading] = useState(false);

  const inputTimerRef = useRef<NodeJS.Timeout | null>(null);

  const isValueInvalid = (value: string) => value.trim() === '' || value.length <= 3;

  const goTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const getDebounceSearchResults = (value: string) => {
    if (inputTimerRef.current) {
      clearTimeout(inputTimerRef.current);
    }

    inputTimerRef.current = setTimeout(async () => {
      setLoading(true);

      const { data } = await getProductsBySearchValue(value);
      dispatch(setHeaderSearchProducts(data));

      setLoading(false);
    }, 500);
  };

  const handleSearchChange = (value: string) => {
    try {
      if (isValueInvalid(value)) {
        return dispatch(setHeaderSearchProducts([]));
      }

      if (value.includes('%')) {
        return toast.error(t('error_search_text'));
      }

      getDebounceSearchResults(value);
    } catch (error) {
      handleError(new Error(`Failed to search a value - ${error}`), 'error', {
        searchValue: value,
        stringifyError: JSON.stringify(error)
      });
    }
  };

  const handleEnterPress = (event, searchValue) => {
    if (event.key === 'Enter') {
      handleRedirectToSearchPage(searchValue);
    }
  };

  const handleClearSearch = () => {
    handleSearchValueChange('');
    dispatch(setHeaderSearchProducts([]));
  };

  const handleProductClick = (id: number) => {
    dispatch(addRecentSearch(searchValue));
    handleClearSearch();
    history.push(`/product/${id}`);
  };

  const handleRedirectToSearchPage = (searchValue: string) => {
    if (isValueInvalid(searchValue)) {
      return toast.error(t('general_error_search_text'));
    }

    dispatch(addRecentSearch(searchValue));
    handleClearSearch();
    goTop();
    history.push(`/search?result=${searchValue}`);
  };

  const handleSearchValueChange = (value: string) => dispatch(setHeaderSearchValue(value));

  const handleRemoveRecentSearch = (value: string) => dispatch(removeRecentSearch(value));

  return {
    searchValue,
    products,
    recentSearches,
    isLoading,
    handleSearchValueChange,
    handleSearchChange,
    handleProductClick,
    handleEnterPress,
    handleRedirectToSearchPage,
    handleRemoveRecentSearch
  };
};
