import { SHOW_SUCCESS_ALERT, HIDE_SUCCESS_ALERT } from '../actionTypes';
import { alertPropsType } from '../responseTypes';

const initialState: alertPropsType = {
  type: 'success',
  text: ''
};

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SUCCESS_ALERT:
      return {
        ...state,
        type: action.payload.type,
        text: action.payload.text
      };
    case HIDE_SUCCESS_ALERT:
      return {
        ...state,
        type: 'success',
        text: ''
      };
    default:
      return state;
  }
}
