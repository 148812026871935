import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers/rootReducer';
import { Redirect } from 'react-router-dom';

export default (props) => {
  const isAuthFromStore = useSelector((state: RootState) => state.auth.isAuth);
  const isAuthFromLS = localStorage.getItem('isAuth');

  return (
    <>{isAuthFromStore || isAuthFromLS ? props.children : <Redirect to={'/authorization'} />}</>
  );
};
