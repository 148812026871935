import {
  FETCH_PRODUCTS_SUCCESS,
  SET_LOAD_MORE,
  SET_LOAD_PRODUCTS,
  SET_PRODUCT_LOADING,
  SET_USER_PHARMACY
} from '../actionTypes';
import { statuses } from '../../common/enums';
import { productsFields } from '../constansts';
import { Dispatch } from 'redux';
import handleError from '../../utils/integrations/sentry';
import { AxiosError } from 'axios';
import { ApiClient } from '../../api/ApiClient';

export function fetchProductsByCategory(categoryId, settings: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingProducts(statuses.pending));
      dispatch(setNothingLoadMore(false));

      const params = {
        ...settings,
        fields: productsFields,
        embed: 'images',
        inStock: true
      };

      const productItems = await ApiClient.get(`/categories/${categoryId}/products`, { params });

      if (!productItems.data.length && settings.skip !== 0) {
        dispatch(setNothingLoadMore(true));
      }

      dispatch(fetchProductsSuccess(productItems));

      dispatch(setLoadingProducts(statuses.completed));
    } catch (e) {
      dispatch(setLoadingProducts(statuses.failed));
    }
  };
}

export function setLoadingProducts(status: any) {
  return {
    type: SET_LOAD_PRODUCTS,
    status: status
  };
}

export function fetchProductsSuccess(products: any) {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    products
  };
}

export function fetchProductById(id: any) {
  return async () => {
    try {
      const data = await ApiClient.get(`/products/${id}`);

      return {
        ...data,
        pharmacies: data['pharmacies'].filter((i) => i.pharmacy.active)
      };
    } catch (error) {
      handleError(error as AxiosError, 'error', { productId: id });
    }
  };
}

export function setNothingLoadMore(b: boolean) {
  return {
    type: SET_LOAD_MORE,
    payload: b
  };
}

export function setProductLoading(loading) {
  return {
    type: SET_PRODUCT_LOADING,
    payload: loading
  };
}

export function setUserPharmacy(ids: Array<number>) {
  return {
    type: SET_USER_PHARMACY,
    payload: ids
  };
}
