import {
  FETCH_PRODUCTS_SUCCESS,
  SET_BASKET_PRODUCTS,
  SET_LOAD_BASKET,
  SET_LOAD_MORE,
  SET_LOAD_PRODUCTS,
  SET_USER_PHARMACY,
  SET_PRODUCT_LOADING,
  SET_WISHLIST
} from '../actionTypes';
import { statuses } from '../../common/enums';
import { ProductAddToBasketType } from '../responseTypes';

const getPharmacyIdsFromProducts = (products: Array<ProductAddToBasketType>) =>
  products.map(({ pharmacy }) => pharmacy.id);

const basketProducts = localStorage.getItem('basketProducts')
  ? JSON.parse(localStorage.getItem('basketProducts') || '')
  : [];

const initialState = {
  loading: false,
  products: [],
  status: statuses.idle,
  error: null,
  loadProducts: false,
  basketProducts: basketProducts,
  loadBasket: false,
  wishlist: [],
  loadMore: false,
  userPharmacies: getPharmacyIdsFromProducts(basketProducts)
};

export default function productsReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products
      };
    case SET_LOAD_PRODUCTS:
      return {
        ...state,
        status: action.status
      };
    case SET_BASKET_PRODUCTS:
      return {
        ...state,
        basketProducts: action.products
      };
    case SET_LOAD_BASKET:
      return {
        ...state,
        loadBasket: action.loadBasket
      };
    case SET_LOAD_MORE:
      return {
        ...state,
        loadMore: action.payload
      };
    case SET_PRODUCT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_WISHLIST:
      return {
        ...state,
        wishlist: action.payload
      };
    case SET_USER_PHARMACY:
      return {
        ...state,
        userPharmacies: action.payload
      };
    default:
      return state;
  }
}
