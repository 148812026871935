import React from 'react';
import { FaAsterisk } from 'react-icons/all';
import { useTranslation } from 'react-i18next';
import ProductUnit from './ProductUnit';
import { ProductPharmacyType } from '../../store/responseTypes';

interface IUnit {
  percentage: string;
  productId: number;
  unit: { id: number; name: string };
  unitId: number;
}
export default ({
  product,
  units,
  pharmacies,
  emptyUnits = false,
  selectedUnit,
  setSelectedUnit,
  selectedPharmacies,
  selectedPharmacy,
  setSelectedPharmacy
}) => {
  const { t } = useTranslation();

  const renderPharmacies = (pharmacies: ProductPharmacyType[]) =>
    pharmacies.map(({ pharmacy, price, quantity, pharmacyId }) => (
      <div className="radio-container" key={pharmacyId}>
        <input
          type="radio"
          checked={pharmacyId === selectedPharmacy}
          onChange={() => setSelectedPharmacy(pharmacyId)}
          id={`${product.id}-${pharmacyId}-${price}`}
        />
        <label htmlFor={`${product.id}-${pharmacyId}-${price}`} className="cro">
          <span className="fix" />
          <span className="fix2" />
        </label>
        <label htmlFor={`${product.id}-${pharmacyId}-${price}`}>
          <p>{pharmacy.name}</p>
          <div className="additionalInfo">
            {`${pharmacy.address}`} <br />
            {`${pharmacy.workingHours}`} <br />
            {`${t('product_pharmacy_phone_short')}. ${pharmacy.phone}`}
          </div>
          <span className="price">{`${quantity} ${t('product_count')}. - ${price}`} грн</span>
        </label>
      </div>
    ));

  return (
    <div className="product-page-options">
      <div className="options-title">
        <h3>{t('product_available_options')}</h3>
      </div>
      <div className="options-list">
        {!emptyUnits && (
          <div className="units">
            <div className="input-field">
              <div className="input-label">
                <span className="required">
                  <FaAsterisk size={8} />
                </span>
                {t('product_available_variants')}
              </div>
              {units.map((unit: IUnit) => (
                <div className="radio-container" key={unit.unitId}>
                  <ProductUnit
                    id={unit.unitId}
                    name={unit.unit.name}
                    selectedUnit={selectedUnit}
                    setSelectedUnit={setSelectedUnit}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="pharmacies">
          <div className="input-field">
            <div className="input-label">
              <span className="required">
                <FaAsterisk size={8} />
              </span>
              {t('product_in_stock_pharmacies')}
            </div>
            {selectedPharmacies.length > 0 && (
              <>
                <h3>{t('product_selected_pharmacies')}</h3>
                {renderPharmacies(selectedPharmacies)}
                <h3>{t('product_all_pharmacies')}</h3>
              </>
            )}
            {renderPharmacies(pharmacies)}
          </div>
        </div>
      </div>
    </div>
  );
};
