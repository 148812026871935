import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const center = { lat: 49.99120982106122, lng: 36.235747603020116 };

const containerStyle = {
  width: '100%',
  height: '400px'
};

export const MyMapComponent = (props) => (
  <LoadScript googleMapsApiKey="AIzaSyCXl2hVgbKMfxnY_4HNXck9bLmTEOuFb3U">
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
      {props.children}
    </GoogleMap>
  </LoadScript>
);
//50.000186023005185, 36.2404888514793
