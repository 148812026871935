import React from 'react';
import About1 from '../../assets/images/about1.jpg';
import About2 from '../../assets/images/about2.jpg';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';
export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <SEO customTitle={t('page_about_us_main_text')} />
      <h2 className="thinTitle">{t('page_about_us_main_text')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>
            <strong>{t('page_about_us_title_text1')}</strong>
          </p>
          <p>{t('page_about_us_text1')}</p>
          <p>{t('page_about_us_text2')}</p>
          <p>{t('page_about_us_text3')}</p>
          <p>{t('page_about_us_text4')}</p>
          <p>
            <img src={About1} alt="about" />
          </p>
          <p>
            <strong>{t('page_about_us_title_text3')}</strong>
          </p>
          <p>{t('page_about_us_text7')}</p>
          <p>{t('page_about_us_text8')}</p>
          <p>{t('page_about_us_text9')}</p>
          <p>{t('page_about_us_text10')}</p>
          <p>{t('page_about_us_text11')}</p>
          <p>{t('page_about_us_text12')}</p>
        </div>
        <div className="col-md-6">
          <p>
            <strong>{t('page_about_us_title_text2')}</strong>
          </p>
          <p>{t('page_about_us_text5')}</p>
          <p>{t('page_about_us_text6')}</p>
          <p>
            <img src={About2} alt="about" />
          </p>
          <p>
            <strong>{t('page_about_us_title_text4')}</strong>
          </p>
          <p>{t('page_about_us_text13')}</p>
          <p>{t('page_about_us_text14')}</p>
          <p>{t('page_about_us_text15')}</p>
          <p>{t('page_about_us_text16')}</p>
          <p>{t('page_about_us_text17')}</p>
          <p>{t('page_about_us_text18')}</p>
          <p>{t('page_about_us_text19')}</p>
        </div>
      </div>
    </div>
  );
};
