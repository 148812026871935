import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import routes from '../routes';

const Breadcrumbs = (props: any) => {
  // const crumbs = useSelector((state: RootState) => state.global.crumbs)
  const crumbs = routes
    .filter(({ path }) => props.location.pathname.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(props.match.params).length
        ? Object.keys(props.match.params).reduce(
            (path, param) => path.replace(`:${param}`, props.match.params[param]),
            path
          )
        : path,
      ...rest
    }));
  return crumbs.length <= 5 ? null : (
    <div>
      {crumbs.map(({ title, path }, key) =>
        key + 1 === crumbs.length ? (
          <span key={key}>{title}</span>
        ) : (
          <NavLink key={key} to={path}>
            {title}
          </NavLink>
        )
      )}
    </div>
  );
};
export default withRouter(Breadcrumbs);
