import * as React from 'react';
import './registration.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { saveDataToLS, successLogin } from '../../store/actions/authorization';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../components/InputComponent';
import { useFormik } from 'formik';
import { VALIDATION } from '../../constants/validation';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import { TIMEOUT_DELAY } from '../../common/common';
import { phoneChecker } from '../../utils/phoneChecker';
import { additionalUserFields } from '../../constants/userData';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import handleError from '../../utils/integrations/sentry';
import { login, registration } from '../../api/Authorization';
import i18next from 'i18next';
import { CircularProgress } from '@material-ui/core';

const FormSchema = Yup.object().shape({
  name: VALIDATION.text,
  phone: VALIDATION.phone,
  email: VALIDATION.email,
  password: VALIDATION.password
});

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const dispatch = useDispatch();

  const handleSubmit = async (value: {
    name: string;
    phone: string;
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    try {
      await registration({
        ...value,
        phone: phoneChecker(value.phone),
        ...additionalUserFields
      });

      const response = await login({ email: value.email, password: value.password });

      toast.success(i18next.t('auth_success_text'));
      dispatch(successLogin(response.user));
      dispatch(saveDataToLS(response));

      history.push('/profile');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }

      handleError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      password: ''
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      delayTimeoutRef.current = setTimeout(async () => await handleSubmit(values), TIMEOUT_DELAY);
    }
  });
  return (
    <div className="reg-page">
      <div className="title">
        <h2>{t('page_registration_title_text')}</h2>
        <p>
          <NavLink to={'/authorization'}>{t('page_registration_text1')}</NavLink>
        </p>
      </div>
      <div className="form-wrapper">
        <div className="title">{t('page_registration_text2')}</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <InputComponent
            label={t('form_input_name')}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={Boolean(formik.errors.name && formik.touched.name)}
            errorText={t('form_input_name_valid_msg')}
            type="text"
            name="name"
            isRequired
            onBlur={formik.handleBlur}
          />
          <InputComponent
            value={formik.values.phone}
            onChange={formik.handleChange}
            label={t('form_input_phone')}
            error={Boolean(formik.errors.phone && formik.touched.phone)}
            errorText={t('form_input_phone_valid_msg')}
            type="tel"
            name="phone"
            isRequired
            onBlur={formik.handleBlur}
          />
          <InputComponent
            value={formik.values.email}
            onChange={formik.handleChange}
            label={t('form_input_email')}
            error={Boolean(formik.errors.email && formik.touched.email)}
            errorText={t('form_input_email_valid_msg')}
            type="email"
            name="email"
            isRequired
            onBlur={formik.handleBlur}
          />
          <InputComponent
            label={t('form_input_password')}
            onChange={formik.handleChange}
            value={formik.values.password}
            error={Boolean(formik.errors.password && formik.touched.password)}
            errorText={t('form_input_password_valid_msg')}
            type="password"
            name="password"
            isRequired
            onBlur={formik.handleBlur}
          />
          <div className="flex-container" style={{ justifyContent: 'flex-end' }}>
            <button type="submit" className="btn withLoaderInside">
              {isLoading ? <CircularProgress size={20} /> : t('page_create_order_sign_up')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
