import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers/rootReducer';
import { FaSignInAlt } from 'react-icons/all';
import { useTranslation } from 'react-i18next';

export type PrivateRouteProps = {
  to: string;
  unAuthTitle?: string;
  children: React.ReactNode;
  classes?: string;
};

export const PrivateRoute = ({ to, unAuthTitle, children, classes }: PrivateRouteProps) => {
  const { t } = useTranslation();

  const isAuthFromStore = useSelector((state: RootState) => state.auth.isAuth);
  const isAuthFromLS = localStorage.getItem('isAuth');

  return (
    <>
      {isAuthFromStore || isAuthFromLS ? (
        <NavLink to={to} className={classes || ''}>
          {children}
        </NavLink>
      ) : (
        <NavLink className="login" to={'/authorization'}>
          {unAuthTitle ? (
            unAuthTitle
          ) : (
            <>
              <FaSignInAlt size={20} />
              <span>{t('header_nav_sign_in')}</span>
            </>
          )}
        </NavLink>
      )}
    </>
  );
};
