import React from 'react';
import { FaShoppingBasket } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import imgPlaceholder from '../../assets/images/product_placeholder.png';

import { IoIosHeartDislike } from 'react-icons/all';
import { useTranslation } from 'react-i18next';
import ErrorBoundaryWrapper from '../../components/ErrorBoundary';
import handleTitleLength from '../../utils/handleTitleLength/handleTitleLength';
import useWishlistHandler from '../../hooks/useWishlistHandler';
import { SearchProductType } from '../../api/search/types';
import { useLocale } from '../../hooks/useLocale';
import { getNameByLocale } from '../../utils/getNameByLocale';

export default ({ product, isWishlist }: { product: SearchProductType; isWishlist?: boolean }) => {
  const { t } = useTranslation();
  const { currentLangCode } = useLocale();

  const { clickHandler } = useWishlistHandler();
  const showProductPrice = (price): string => {
    if (!price) return t('product_out_of_stock');
    if (price.min === price.max) return `${price.min} грн`;
    return `${price.min} - ${price.max} грн`;
  };

  const productName = getNameByLocale(product, currentLangCode);

  return (
    <div className="product-layout">
      <div className="product-image text-center">
        <NavLink to={`/product/${product.id}`}>
          <img
            src={product.images.length ? product.images[0].uri : imgPlaceholder}
            alt={productName}
            title={productName}
          />
        </NavLink>
      </div>
      <div className="product-info">
        <div className="product-caption">
          <h4>
            <NavLink to={`/product/${product.id}`}>{handleTitleLength(productName)}</NavLink>
          </h4>
          <span className="product-price">{showProductPrice(product.price)}</span>
        </div>
        <ErrorBoundaryWrapper>
          <div className="product-buttonGroup">
            <NavLink className="product-buttonGroup-buy" to={`/product/${product.id}`}>
              <FaShoppingBasket size={22} />
              {t('add_to_basket')}
            </NavLink>
            <button
              onClick={() =>
                product.id && clickHandler({ id: product.id, isWishlist: isWishlist || false })
              }
              title={t('add_to_wishlist')}
              className="product-buttonGroup-mini"
            >
              {isWishlist ? <IoIosHeartDislike size={18} /> : <FaHeart size={18} />}
            </button>
            {/*<button onClick={() => {*/}
            {/*	dispatch(addCompareProduct(product));*/}
            {/*}} title="В сравнение" className="product-buttonGroup-mini"><FaExchangeAlt size={18}/></button>*/}
          </div>
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};
