import React from 'react';
import Zakaz from '../../assets/images/photo-zakaz.jpg';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <SEO customTitle={t('page_how_to_order_title_text1')} />
      <h2 className="thinTitle">{t('page_how_to_order_title_text1')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>{t('page_how_to_order_text1')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text2')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text3')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text4')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text5')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text6')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text7')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text8')}</p>
          <p style={{ marginLeft: 20 }}>{t('page_how_to_order_text9')}</p>
          <p>{t('page_how_to_order_text10')}</p>
        </div>
        <div className="col-md-6">
          <div className="singleImg">
            <img src={Zakaz} alt="License" />
          </div>
        </div>
      </div>
    </div>
  );
};
