import React, { useMemo, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { RiErrorWarningFill } from 'react-icons/all';
import OrderTable from './OrderTable';
import { setBasketProductsSum } from '../../hooks/basketHooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';

import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';
import ErrorBoundaryWrapper from '../../components/ErrorBoundary';
import InputComponent from '../../components/InputComponent';
import { VALIDATION } from '../../constants/validation';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useGetStoreData from '../../hooks/useGetStoreData';
import { TIMEOUT_DELAY } from '../../common/common';
import { createOrder } from '../../api/Orders';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { successBasketChange } from '../../store/actions/basket';
import axios, { AxiosError } from 'axios';
import handleError from '../../utils/integrations/sentry';
import { CircularProgress } from '@material-ui/core';
import { phoneChecker } from '../../utils/phoneChecker';
import sortBasketProducts from '../../utils/sortBasketProducts/sortBasketProducts';

interface IFormData {
  name: string;
  phone: string;
  email: string;
  comment: string;
}

const FormSchema = Yup.object().shape({
  name: VALIDATION.text,
  phone: VALIDATION.phone,
  email: VALIDATION.emailOptional,
  comment: VALIDATION.comment
});
export default () => {
  const { t } = useTranslation();

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useGetStoreData();
  const basketProducts = useSelector((state: RootState) => state.products.basketProducts);
  const basketProductsSum = useMemo(() => setBasketProductsSum(basketProducts), [basketProducts]);
  const [isLoading, setIsLoading] = useState(false);

  const renderUserData = () => (
    <div className="userData">
      <p>
        <strong>{t('page_create_order_text10')}</strong> {user.name}
      </p>
      <p>
        <strong>{t('page_create_order_text11')}</strong> {user.phone}
      </p>
      <p>
        <strong>{t('page_create_order_text12')}</strong> {user.email}
      </p>
    </div>
  );

  const handleSubmit = async (data: IFormData) => {
    setIsLoading(true);

    const sortedBasketProducts = sortBasketProducts(basketProducts);
    try {
      const promises = sortedBasketProducts.map(async (basketProducts) => {
        try {
          await createOrder({
            ...data,
            phone: phoneChecker(data.phone),
            products: basketProducts.map((item) => ({
              productId: item.id,
              pharmacyId: item.pharmacy.id,
              unitId: item.unit.unitId,
              quantity: String(item.count)
            }))
          });
        } catch (error) {
          console.error(error);
        }
      });
      await Promise.allSettled(promises);

      toast.success(i18next.t('order_created_success'));

      dispatch(successBasketChange([]));

      localStorage.removeItem('basketProducts');

      history.push('/');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }

      handleError(error as AxiosError, 'fatal');
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      phone: user?.phone || '',
      email: user?.email || '',
      comment: ''
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      delayTimeoutRef.current = setTimeout(async () => await handleSubmit(values), TIMEOUT_DELAY);
    }
  });

  return (
    <div className="order-creation">
      <SEO customTitle={t('page_create_order_title_text1')} />
      <h1>{t('page_create_order_title_text1')}</h1>
      {basketProducts.length ? (
        <ErrorBoundaryWrapper>
          <OrderTable />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="title-box">
                  <span>{t('page_create_order_title_text2')}</span>
                  {user ? (
                    user.name
                  ) : (
                    <NavLink to={'/authorization'}>
                      {t('page_authorization_already_registered')}
                    </NavLink>
                  )}
                </div>
                {user ? (
                  renderUserData()
                ) : (
                  <div className="form-order">
                    <InputComponent
                      label={t('form_input_name')}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      error={Boolean(formik.errors.name && formik.touched.name)}
                      errorText={t('form_input_name_valid_msg')}
                      type="text"
                      name="name"
                      isRequired
                      onBlur={formik.handleBlur}
                    />
                    <InputComponent
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      label={t('form_input_phone')}
                      error={Boolean(formik.errors.phone && formik.touched.phone)}
                      errorText={t('form_input_phone_valid_msg')}
                      type="tel"
                      name="phone"
                      isRequired
                      onBlur={formik.handleBlur}
                    />
                    <InputComponent
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      label={t('form_input_email')}
                      error={Boolean(formik.errors.email && formik.touched.email)}
                      errorText={t('form_input_email_valid_msg')}
                      type="email"
                      name="email"
                      onBlur={formik.handleBlur}
                    />
                    <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                      <label className="input-label">{t('form_input_comment')}</label>
                      <textarea
                        className="input-field"
                        placeholder={t('form_input_message')}
                        onChange={formik.handleChange}
                        value={formik.values.comment}
                        name="comment"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="title-box">
                  <span>{t('page_create_order_title_text3')}</span>
                </div>
                <div className="radio-container">
                  <input
                    type="radio"
                    id="delivery"
                    checked
                    onChange={() => null}
                    value={t('page_create_order_delivery_bottom') as string}
                  />
                  <label htmlFor="delivery">{t('page_create_order_delivery_bottom')}</label>
                </div>
                <div className="title-box">
                  <span>{t('page_create_order_title_text4')}</span>
                </div>
                <div className="radio-container">
                  <input
                    type="radio"
                    id="payment"
                    checked
                    onChange={() => null}
                    value={t('page_create_order_payment_bottom') as string}
                  />
                  <label htmlFor="payment">{t('page_create_order_payment_bottom')}</label>
                </div>
              </div>
            </div>
            <div className="order-creation-footer">
              <div className="order-creation-footer-price">
                <p>
                  {t('page_create_order_text9')} <strong>{basketProductsSum.toFixed(2)} грн</strong>
                </p>
              </div>
              <div className="order-creation-footer-attention">
                <RiErrorWarningFill size={50} />
                <p>{t('page_create_order_attention')}</p>
              </div>
              <div className="order-creation-footer-btn">
                <button type="submit" className="btn withLoaderInside giant">
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    t('page_create_order_checkout_bottom')
                  )}
                </button>
              </div>
            </div>
          </form>
        </ErrorBoundaryWrapper>
      ) : (
        <div className="text-center no-products">
          <p className="warning">{t('page_create_order_impossible_create')}</p>
          <span>{t('page_create_order_empty_basket')}</span>
        </div>
      )}
    </div>
  );
};
