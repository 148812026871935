import {
  SET_HEADER_SEARCH_VALUE,
  SET_HEADER_SEARCH_PRODUCTS,
  SET_RECENT_SEARCHES
} from '../actionTypes';

const initialState = {
  value: '',
  products: [],
  recentValues: localStorage.getItem('recentSearches')
    ? JSON.parse(localStorage.getItem('recentSearches') || '')
    : []
};

export default function searchReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_HEADER_SEARCH_VALUE:
      return {
        ...state,
        value: action.payload
      };
    case SET_HEADER_SEARCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case SET_RECENT_SEARCHES:
      localStorage.setItem('recentSearches', JSON.stringify(action.payload));
      return {
        ...state,
        recentValues: action.payload
      };
    default:
      return state;
  }
}
