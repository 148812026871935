import React, { useRef } from 'react';

import { useFormik } from 'formik';
import InputComponent from '../InputComponent';
import { useTranslation } from 'react-i18next';
import useGetStoreData from '../../hooks/useGetStoreData';
import { VALIDATION } from '../../constants/validation';
import * as Yup from 'yup';
import { TIMEOUT_DELAY } from '../../common/common';

const FormSchema = Yup.object().shape({
  name: VALIDATION.text,
  phone: VALIDATION.phone,
  email: VALIDATION.email
});

const FormUserInfo = ({ handleSubmit }) => {
  const userData = useGetStoreData();

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      name: userData?.name || '',
      phone: userData?.phone || '',
      email: userData.email || ''
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      delayTimeoutRef.current = setTimeout(async () => await handleSubmit(values), TIMEOUT_DELAY);
    }
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <InputComponent
        label={t('form_input_name')}
        onChange={formik.handleChange}
        value={formik.values.name}
        error={Boolean(formik.errors.name && formik.touched.name)}
        errorText={t('form_input_name_valid_msg')}
        type="text"
        name="name"
        isRequired
        onBlur={formik.handleBlur}
      />
      <InputComponent
        value={formik.values.phone}
        onChange={formik.handleChange}
        label={t('form_input_phone')}
        error={Boolean(formik.errors.phone && formik.touched.phone)}
        errorText={t('form_input_phone_valid_msg')}
        type="tel"
        name="phone"
        isRequired
        onBlur={formik.handleBlur}
      />
      <InputComponent
        value={formik.values.email}
        onChange={formik.handleChange}
        label={t('form_input_email')}
        error={Boolean(formik.errors.email && formik.touched.email)}
        errorText={t('form_input_email_valid_msg')}
        type="email"
        name="email"
        isRequired
        onBlur={formik.handleBlur}
      />
      <div className="flex-container" style={{ justifyContent: 'flex-end' }}>
        <button type="submit" className="btn">
          {t('page_profile_save_buttom')}
        </button>
      </div>
    </form>
  );
};
export default FormUserInfo;
