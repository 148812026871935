import {
  LoginResponseType,
  LoginType,
  RegisterResponseType,
  RegisterType
} from '../store/responseTypes';
import { ApiClient } from './ApiClient';
import { UserInfoType, UserResetPasswordType } from '../views/Profile/UserData';
import { LOCALSTORAGE_KEYS } from '../constants/constants';
import i18next from 'i18next';

export const login = async (data: LoginType) =>
  await ApiClient.post<LoginType, LoginResponseType>('/authentication/login', data);

export const registration = async (data: RegisterType) =>
  await ApiClient.post<RegisterType, RegisterResponseType>('/authentication/register', data);

export const updateUserData = async (data: UserInfoType) =>
  await ApiClient.patch<UserInfoType, RegisterResponseType>('/users/me', data);

export const changePassword = async (data: UserResetPasswordType) =>
  await ApiClient.put<UserResetPasswordType>('/authentication/passwords', data);

export const getRefreshToken = async () => {
  const params = {
    refreshToken: localStorage.getItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN) || ''
  };
  // TODO: replace any
  const result: any = await ApiClient.get('/authentication/refresh', {
    params
  });
  localStorage.setItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN, result.accessToken);
  return result.accessToken;
};

export const translateErrorMessage = (status: number) => {
  switch (status) {
    case 400:
      return i18next.t('request_error_text_400');
    case 401:
      return i18next.t('request_error_text_401');
    case 500:
      return i18next.t('request_error_text_500');
    case 403:
      return i18next.t('request_error_text_403');
    case 409:
      return i18next.t('request_error_text_409');
    case 404:
      return i18next.t('request_error_text_404');
    default:
      return i18next.t('request_error_text_default');
  }
};
