import React, { useMemo } from 'react';
import { AiOutlineClose, AiOutlineShopping } from 'react-icons/all';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { NavLink } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { setBasketProductsSum } from '../../hooks/basketHooks';
import { useTranslation } from 'react-i18next';
import BasketProductMarkup from './BasketProductMarkup';
import { ProductAddToBasketType } from '../../store/responseTypes';

export default (props: any) => {
  const { t } = useTranslation();

  const basketProducts = useSelector((state: RootState) => state.products.basketProducts);
  const loading = useSelector((state: RootState) => state.products.loadBasket);
  const basketClasses = ['basket-wrapper'];

  if (props.isOpen) {
    basketClasses.push('active');
  }

  const basketProductsSum = useMemo(() => setBasketProductsSum(basketProducts), [basketProducts]);
  const basketProductsCount = basketProducts.reduce((acc: number, item: any) => {
    acc += item.count;
    return acc;
  }, 0);

  return (
    <div className={basketClasses.join(' ')}>
      <div className="basket-view">
        <div className="close inverted" onClick={() => props.onClose()}>
          <AiOutlineClose size={30} color={'#fff'} />
        </div>
        <div className="basket-content">
          <div className="basket-buy-info">
            <AiOutlineShopping size={70} color={'#000'} />
            <div className="count">{basketProductsCount}</div>
            <div className="price">
              {t('product_total')}: {basketProductsSum} грн
            </div>
          </div>
          {basketProducts.length ? (
            loading ? (
              <div className="basket-loading">
                <Spinner />
              </div>
            ) : (
              <div className="basket-list">
                {basketProducts.map((item: ProductAddToBasketType) => (
                  <BasketProductMarkup key={item.id} item={item} />
                ))}
              </div>
            )
          ) : (
            <div className="basket-list">
              <h3 className="text-center" style={{ paddingTop: 20 }}>
                {t('basket_empty')}
              </h3>
            </div>
          )}
        </div>
        <NavLink to={'/create-order'}>
          <div className="basket-buy-btn">{t('basket_order_create')}</div>
        </NavLink>
      </div>
      <div className="overlay" onClick={() => props.onClose()} />
    </div>
  );
};
