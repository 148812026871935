import React, { createContext, useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import Breadcrumbs from '../components/Breadcrumbs';
import Footer from '../components/Footer';
import BasketSidebar from './Basket/BasketSidebar';
import { useLocation } from 'react-router';
import SEO from '../components/SEO';
import useIsInViewport from '../hooks/useIsInViewport';
const INITIAL_STATE = {
  isVisible: false
};
interface IFooterContext {
  isVisible: boolean;
}
export const FooterContext = createContext<IFooterContext>(INITIAL_STATE);

export default (props: any) => {
  const [basketOpen, setBasketOpen] = useState(false);
  const [isFooterState, setIsFooterState] = useState(false);

  const footerRef = useRef<null>(null);
  useIsInViewport(footerRef, setIsFooterState);

  const location = useLocation();
  useEffect(() => {
    setBasketOpen(false);
  }, [location]);

  return (
    <div className="page-container">
      <SEO />
      <Header basketOpen={() => setBasketOpen(true)} />
      <BasketSidebar isOpen={basketOpen} onClose={() => setBasketOpen(false)} />
      <div className="container content-wrapper">
        <Breadcrumbs />
        <FooterContext.Provider value={{ isVisible: isFooterState }}>
          <main>{props.children}</main>
        </FooterContext.Provider>
      </div>

      <Footer ref={footerRef} />
    </div>
  );
};
