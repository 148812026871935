import {
  SET_HEADER_SEARCH_PRODUCTS,
  SET_HEADER_SEARCH_VALUE,
  SET_RECENT_SEARCHES
} from '../actionTypes';
import { SearchProductType } from '../../api/search/types';

export function setHeaderSearchValue(value: string) {
  return {
    type: SET_HEADER_SEARCH_VALUE,
    payload: value
  };
}

export function setHeaderSearchProducts(products: SearchProductType[]) {
  return {
    type: SET_HEADER_SEARCH_PRODUCTS,
    payload: products
  };
}

export function addRecentSearch(value: string) {
  return (dispatch, getState) => {
    if (value.trim() === '' || value.length <= 3) return;
    const items = getState().search.recentValues;

    if (!items.includes(value) && value !== '') {
      items.push(value);
    }

    dispatch(setRecentSearches(items));
  };
}

export function removeRecentSearch(value: string) {
  return (dispatch, getState) => {
    const items = getState().search.recentValues;

    dispatch(setRecentSearches(items.filter((item) => item !== value)));
  };
}

export function setRecentSearches(values: string[]) {
  return {
    type: SET_RECENT_SEARCHES,
    payload: values
  };
}
