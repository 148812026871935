import React from 'react';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';
import { FallbackErrorComponent } from './FallbackErrorComponent';

interface ErrorBoundaryWrapperProps {
  children: React.ReactNode;
  onError?: () => void;
}
const ErrorBoundaryWrapper = ({ children, onError }: ErrorBoundaryWrapperProps) => {
  const location = useLocation();

  const errorHandlerToReportSentry = (error: unknown) => {
    Sentry.captureException(error, { extra: { route: location.pathname } });
  };

  return (
    <Sentry.ErrorBoundary
      // @ts-ignore - Fallback component prop is not typed by third party package
      FallbackComponent={FallbackErrorComponent}
      onError={(error: unknown) => {
        errorHandlerToReportSentry(error);
        onError?.();
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
