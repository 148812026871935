import React from 'react';
import { PrivateRoute } from './PrivateRoute';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers/rootReducer';
import { useTranslation } from 'react-i18next';

export type Link = {
  title: string;
  link: string;
  action?: string;
  isPrivate?: boolean;
};

export const HeaderNav = () => {
  const { t } = useTranslation();

  const wishlistProducts = useSelector((state: RootState) => state.wishlist.products);

  const links: Link[] = [
    {
      title: t('header_nav_map'),
      link: '/map'
    },
    {
      title: t('header_nav_main'),
      link: '/'
    },
    {
      title: `${t('header_nav_wishlist', { count: wishlistProducts.length })}`,
      link: '/profile/wishlist',
      isPrivate: true
    },
    {
      title: t('header_nav_order_creation'),
      link: '/create-order'
    },
    {
      title: t('header_nav_contacts'),
      link: '/contacts'
    }
    // {
    //   title: t('header_nav_blog'),
    //   link: '/posts'
    // }
  ];

  const renderNavs = (navLinks: Array<Link>) =>
    navLinks.map(({ title, isPrivate, link }, index) => (
      <li key={index}>
        {isPrivate ? (
          <PrivateRoute to={link} unAuthTitle={title}>
            {title}
          </PrivateRoute>
        ) : (
          <NavLink to={link}>{title}</NavLink>
        )}
      </li>
    ));

  return <ul className="flex-container">{renderNavs(links)}</ul>;
};
