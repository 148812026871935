import { Modal } from '@material-ui/core';
import React from 'react';

export default ({ open, close, children, title }) => (
  <Modal open={open} onClose={close}>
    <div className="modal">
      <div className="modal-title">
        <h3>{title}</h3>
      </div>
      <div className="modal-body">
        <div className="text-center">{children}</div>
      </div>
    </div>
  </Modal>
);
