import * as React from 'react';
import { successLogin } from '../../store/actions/authorization';
import { useTranslation } from 'react-i18next';
import AuthValidation from '../../hoc/AuthValidation';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FormUserInfo from '../../components/forms/FormUserInfo';
import FormPassword from '../../components/forms/FormPassword';
import axios from 'axios';
import { changePassword, updateUserData } from '../../api/Authorization';
import { phoneChecker } from '../../utils/phoneChecker';

export type UserInfoType = { name: string; phone: string; email: string };

export type UserResetPasswordType = {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
};

export default () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleUserInfoSubmit = async (data: UserInfoType) => {
    try {
      const response = await updateUserData({
        ...data,
        phone: phoneChecker(data.phone)
      });
      dispatch(successLogin(response));
      localStorage.setItem('user', JSON.stringify(response));
      toast.success(t('change_data_success_text'));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
    }
  };

  const handlePasswordChangeSubmit = async (data: UserResetPasswordType) => {
    try {
      await changePassword(data);
      toast.success(t('change_data_success_text'));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
    }
  };

  return (
    <AuthValidation>
      <div className="form-wrapper">
        <div className="title">{t('page_profile_title_text2')}</div>
        <FormUserInfo handleSubmit={handleUserInfoSubmit} />
      </div>
      <div className="form-wrapper">
        <div className="title">{t('page_profile_change_pass')}</div>
        <FormPassword handleSubmit={handlePasswordChangeSubmit} />
      </div>
    </AuthValidation>
  );
};
