import React from 'react';
import './i18n';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux';

import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/styles.scss';

import App from './App';
import ScrollToTop from './components/ScrollToTop';
import { rootReducer } from './store/reducers/rootReducer';
import { ToastContainer } from 'react-toastify';
import { Zoom } from 'react-toastify';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_ADDRESS,
  integrations: [new Sentry.BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: 1.0 // Capture 100% of the transactions, reduce in production!
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        limit={3}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
        transition={Zoom}
        closeButton={false}
        style={{ fontSize: '16px', top: 50 }}
      />
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>
);
