import React from 'react';
import useUnitNamesRedactor from '../../hooks/useUnitNamesRedactor';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { changeBasketItemCount, removeBasketItem } from '../../store/actions/basket';
import { useTranslation } from 'react-i18next';
import { ProductAddToBasketType } from '../../store/responseTypes';
import handleTitleLength from '../../utils/handleTitleLength/handleTitleLength';
import { NavLink } from 'react-router-dom';
import imgPlaceholder from '../../assets/images/product_placeholder.png';
import { AiOutlineMinus } from 'react-icons/ai';
import { BiTrashAlt } from 'react-icons/bi';
import { CgMathPlus } from 'react-icons/cg';
import { useLocale } from '../../hooks/useLocale';
import { getNameByLocale } from '../../utils/getNameByLocale';

const BasketProductMarkup = ({ item }: { item: ProductAddToBasketType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentLangCode } = useLocale();

  const unityName = useUnitNamesRedactor(item.unit?.unit.name);

  const productUrl = `/product/${item.id}`;
  const productName = handleTitleLength(getNameByLocale(item, currentLangCode));

  const incrementProduct = (product: ProductAddToBasketType) => {
    const userCount = product.count * Number(product.unit?.percentage);
    if (userCount >= Number(product.quantity)) {
      toast.error(t('product_warning_not_enough'));
      return;
    }
    dispatch(
      changeBasketItemCount({
        ...product,
        count: product.count + 1
      })
    );
  };

  const decrementProduct = (product: ProductAddToBasketType) => {
    if (product.count === 1) {
      toast.error(t('basket_warning_min_count'));
      return;
    }
    product.count--;
    dispatch(changeBasketItemCount(product));
  };
  return (
    <div className="basketItem" key={item.id}>
      <div className="basketItem-img">
        <NavLink to={productUrl}>
          <img
            src={item.images?.length ? item.images[0].uri : imgPlaceholder}
            alt={productName}
            title={productName}
          />
        </NavLink>
      </div>
      <div className="basketItem-info">
        <div className="basketItem-desc">
          <div>
            <h3>
              <NavLink to={productUrl}>{productName}</NavLink>
            </h3>
            <span className="unit-name">
              {t('basket_available_product_variants')}: {unityName}
            </span>
            <br />
            <span>
              {/*{t('basket_available_product_pharmacy')}: {item.pharmacy.address}*/}
              {item.pharmacy.name}: {item.pharmacy.address}
            </span>
          </div>
          <div className="basketItem-remove" onClick={() => dispatch(removeBasketItem(item))}>
            <BiTrashAlt size={25} color={'#888'} />
          </div>
        </div>
        <div className="basketItem-actions">
          <div className="basketItem-calc">
            <span onClick={() => decrementProduct(item)}>
              <AiOutlineMinus size={20} />
            </span>
            <span className="count">{item.count}</span>
            <span onClick={() => incrementProduct(item)}>
              <CgMathPlus size={20} />
            </span>
          </div>
          <div className="basketItem-price">
            <span className="count">
              {item.fullPrice
                ? `x${item.count} - ${(item.fullPrice * item.count).toFixed(2)} грн`
                : null}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketProductMarkup;
