import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import MainLayout from './views/MainLayout';
import routes from './routes';
import { useLocale } from './hooks/useLocale';

function App() {
  const { baseRouteUrl } = useLocale();

  const routesMaker = (routes: any) => {
    const links = routes.map((item: any, key: any) => (
      <Route
        key={key}
        path={`${baseRouteUrl}/${item.path}`}
        component={item.component}
        exact={item.isExact}
      />
    ));

    return (
      <Switch>
        {links}
        <Redirect to={`/`} />
      </Switch>
    );
  };

  return <MainLayout>{routesMaker(routes)}</MainLayout>;
}

export default withRouter(App);
