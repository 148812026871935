import React, { useEffect, useState } from 'react';
import ProductView from '../Catalog/ProductView';
import { useTranslation } from 'react-i18next';
import { SearchProductType } from '../../api/search/types';
import { useQuery } from '../../hooks/useQuery';
import Spinner from '../../components/Spinner';
import { getProductsBySearchValue } from '../../api/search/search';

export default () => {
  const { t } = useTranslation();

  const { result } = useQuery();
  const urlParsedValue = result as string;

  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState<SearchProductType[]>([]);

  useEffect(() => {
    const get = async () => {
      const { data } = await getProductsBySearchValue(urlParsedValue);
      setProducts(data);
      setLoading(false);
    };
    get();
  }, [urlParsedValue]);

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">
        {t('search_page_results_title')}: {urlParsedValue}
      </h2>
      <div style={{ marginTop: 30 }}>
        {isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : products && products.length ? (
          <div className="row equal">
            {products.slice(0, 100).map((product: SearchProductType) => (
              <div key={product.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <ProductView product={product} isWishlist={false} />
              </div>
            ))}
          </div>
        ) : (
          <div className="col-xs-12">
            <h3 className="text-center" style={{ marginTop: 30 }}>
              {t('search_page_results_empty')}
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};
