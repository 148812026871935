import { ProductPharmacyType } from '../store/responseTypes';
import * as _ from 'lodash';

/*
 * Returns 2 arrays, all pharmacies ordered by ID and selectedPharmacies
 * Order by pharmacy number
 * */

export const useAvailablePharmacies = (
  pharmacies: ProductPharmacyType[],
  selectedPharmacyIds: Array<number>
) => {
  const selectedPharmacies = pharmacies.filter(({ pharmacyId }) =>
    selectedPharmacyIds.includes(pharmacyId)
  );

  return {
    pharmacies: _.orderBy(_.difference(pharmacies, selectedPharmacies), ['pharmacyId'], ['asc']),
    selectedPharmacies
  };
};
