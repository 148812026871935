import React, { useMemo, useEffect, useRef, useState } from 'react';
import { AiOutlineMinus, CgMathPlus, FaHeart, FaTags } from 'react-icons/all';
import { Product } from '../../store/responseTypes';
import ImagePopup from '../../components/ImagePopup';
import ImageContainer from '../../components/ImageContainer';
import Parser from 'html-react-parser';
import { CircularProgress } from '@material-ui/core';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';
import imgPlaceholder from '../../assets/images/product_placeholder.png';

import useWishlistHandler from '../../hooks/useWishlistHandler';
import { useLocale } from '../../hooks/useLocale';
import { getNameByLocale } from '../../utils/getNameByLocale';

type ProductInfoProps = {
  product: Product;
  price: string;
  changeQuantityHandler: any;
  selectedQuantity: any;
  loading: any;
  addProductToBasket: any;
  error: any;
};

const ImgPlaceholder = ({ alt }: { alt: string }) => <img src={imgPlaceholder} alt={alt} />;

export const ProductInfo = ({
  product,
  price,
  changeQuantityHandler,
  selectedQuantity,
  loading,
  addProductToBasket,
  error
}: ProductInfoProps) => {
  const { t } = useTranslation();
  const { currentLangCode } = useLocale();

  const productIsInStock = useMemo(() => price !== '0', [price]);
  const descriptionRef = useRef();

  useEffect(() => {
    if (product.description) {
      let value = product.description || '';
      if (value.indexOf('&lt;') !== -1) {
        // @ts-ignore
        value = Parser(product.description || '');
      }
      // @ts-ignore
      descriptionRef.current.innerHTML = value;
    }
  }, [product]);

  const [imageModal, setImageModal] = useState(false);

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'noEffects'
  };

  const productImages = product.images.filter((item) => item.uri);
  const { clickHandler: addToWishlist } = useWishlistHandler();

  const productName = getNameByLocale(product, currentLangCode);

  return (
    <div className="product-page-view">
      <SEO customTitle={productName} />
      <div className="product-page-view-title">{productName}</div>
      <div className="product-page-view-info">
        <div className="img">
          {productImages.length ? (
            <>
              <Slider {...settings}>
                {productImages.map((img) => (
                  <div key={img.id}>
                    <ImageContainer src={img.uri} name={productName} style={{ width: '100%' }} />
                  </div>
                ))}
              </Slider>
              <div className="img-popup" onClick={() => setImageModal(true)}>
                {t('product_img_zoom')}
              </div>
            </>
          ) : (
            <ImgPlaceholder alt={productName} />
          )}
        </div>
        <div className="desc">
          <div className="desc-options">
            <p>
              <span>{t('product_manufacturer')}:</span> {product.manufacturer}
            </p>
            <p>
              <span>{t('product_code')}:</span> {product.id}
            </p>
          </div>
          <div className="desc-price">
            {productIsInStock ? (
              <>
                <span>
                  <FaTags size={20} />
                  {t('product_price')}:
                </span>
                <h2>{price} грн</h2>
              </>
            ) : (
              <h2>{t('product_out_of_stock')}</h2>
            )}
          </div>
          <div className="warnings">{error}</div>
          <div className="product-page-buy">
            <div className="basketItem-calc">
              <span onClick={() => changeQuantityHandler('-')}>
                <AiOutlineMinus size={20} />
              </span>
              <span className="count">{selectedQuantity}</span>
              <span onClick={() => changeQuantityHandler('+')}>
                <CgMathPlus size={20} />
              </span>
            </div>
            <div className="product-page-buy-buttonBlock ">
              <div className="actionsBlock">
                <button
                  className={`btn-product-buy ${loading ? 'btn-product-buy-loading' : ''} 
                ${!productIsInStock ? 'btn-product-buy-disabled' : ''}`}
                  onClick={() => addProductToBasket()}
                  disabled={!productIsInStock}
                >
                  {!loading ? (
                    t('product_add_to_basket')
                  ) : (
                    <CircularProgress className="progressBar" color={'inherit'} />
                  )}
                </button>
                <button
                  onClick={() => product.id && addToWishlist({ id: product.id, isWishlist: false })}
                  title={t('add_to_wishlist')}
                  className="btn-product-add-wishlist"
                >
                  <FaHeart size={18} />
                </button>
              </div>
              {selectedQuantity > 1 ? (
                <p>
                  {t('product_total')}: {(selectedQuantity * Number(price)).toFixed(2)}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {product.description && product.description.length && (
        // @ts-ignore
        <div className="product-page-view-description" ref={descriptionRef} />
      )}
      <ImagePopup open={imageModal} close={() => setImageModal(false)} title={productName}>
        <Slider {...settings} dots className="border-padding">
          {product.images.map((img) => (
            <div key={img.id}>
              <ImageContainer imageClass="modal-image-container" src={img.uri} name={productName} />
            </div>
          ))}
        </Slider>
      </ImagePopup>
    </div>
  );
};
