import React, { useEffect, useState } from 'react';

import { MainArticle } from './ArticlePreview';
import { useParams } from 'react-router';
import { ApiClient } from '../../api/ApiClient';
import { Post } from '../../store/responseTypes';
import SEO from '../../components/SEO';
import Spinner from '../../components/Spinner';
import axios, { AxiosError } from 'axios';
import handleError from '../../utils/integrations/sentry';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ArticlePost = () => {
  const dispatch = useDispatch();

  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | undefined>(undefined);

  useEffect(() => {
    const fetchArticlePost = async () => {
      try {
        const response = await ApiClient.get<string, Post>(`/storage/blog/slug=${slug}`);
        setPost(response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.error(error.response?.data.message);
        }

        handleError(error as AxiosError);
      }
    };
    fetchArticlePost();
  }, [slug, dispatch]);

  if (!post) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <SEO
        customTitle={post.meta?.title}
        customDescription={post.meta?.description}
        customKeywords={post.meta?.keywords}
      />
      <MainArticle {...post} isLinkVisible={false} />
      <div className="article articlePost" dangerouslySetInnerHTML={{ __html: post.body }} />
    </>
  );
};
export default ArticlePost;
