import React, { useRef, useState } from 'react';
import './authorization.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { saveDataToLS, successLogin } from '../../store/actions/authorization';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';
import { useFormik } from 'formik';
import { VALIDATION } from '../../constants/validation';
import * as Yup from 'yup';
import InputComponent from '../../components/InputComponent';
import { useDispatch } from 'react-redux';
import { TIMEOUT_DELAY } from '../../common/common';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import axios from 'axios';
import { login } from '../../api/Authorization';
import { CircularProgress } from '@material-ui/core';
import { string } from 'yup';

const FormSchema = Yup.object().shape({
  password: string().lowercase().required('Required'),
  email: VALIDATION.email
});
export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const handleSubmit = async (value) => {
    setIsLoading(true);
    try {
      const response = await login(value);

      toast.success(i18next.t('auth_success_text'));
      dispatch(successLogin(response.user));
      dispatch(saveDataToLS(response));

      history.push('/profile');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      email: ''
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      delayTimeoutRef.current = setTimeout(async () => await handleSubmit(values), TIMEOUT_DELAY);
    }
  });

  return (
    <div className="auth-page">
      <SEO customTitle={t('auth_page_title')} />
      <div className="row">
        <div className="col-md-6">
          <div className="signup">
            <h2>{t('page_authorization_title_text1')}</h2>
            <p>
              <strong>{t('page_authorization_text1')}</strong>
            </p>
            <p>{t('page_authorization_text2')}</p>
            <NavLink className="btn" to={'/registration'}>
              {t('page_authorization_buttom_continue')}
            </NavLink>
          </div>
        </div>
        <div className="col-md-6">
          <div className="login">
            <h2>{t('page_authorization_already_registered')}</h2>
            <p>
              <strong>{t('auth_page_title')}</strong>
            </p>
            <div className="form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <InputComponent
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  label={t('form_input_email')}
                  error={Boolean(formik.errors.email && formik.touched.email)}
                  errorText={t('form_input_email_valid_msg')}
                  type="email"
                  name="email"
                  isRequired
                  onBlur={formik.handleBlur}
                />
                <InputComponent
                  label={t('form_input_password')}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={Boolean(formik.errors.password && formik.touched.password)}
                  errorText={t('form_input_required_error')}
                  type="password"
                  name="password"
                  isRequired
                  onBlur={formik.handleBlur}
                />
                <button type="submit" className="btn withLoaderInside">
                  {isLoading ? <CircularProgress size={20} /> : t('header_nav_sign_in')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
