import { ProductAddToBasketType } from '../../store/responseTypes';

const sortBasketProducts = (basketProducts: ProductAddToBasketType[]) => {
  const reducedBasketProducts = basketProducts.reduce((acc, product) => {
    const pharmacyId = product.pharmacy.id;

    if (acc[pharmacyId]) {
      acc[pharmacyId].push(product);
    } else {
      acc[pharmacyId] = [product];
    }
    return acc;
  }, {});
  const arrayFromObject: ProductAddToBasketType[][] = Object.values(reducedBasketProducts);
  return arrayFromObject;
};

export default sortBasketProducts;
