import { useTranslation } from 'react-i18next';

const useUnitNamesRedactor = (name: string | undefined) => {
  const { t } = useTranslation();
  if (!name) return '';

  switch (name.split('.')[0].toLowerCase()) {
    case 'уп':
      return t('united_name_package');
    case 'шт':
      return t('united_name_piece');
    case 'фл':
      return t('united_name_flacon');
    case 'амп':
      return t('united_name_ampoule');
    case 'блист':
      return t('united_name_blister');
    case 'пак':
      return t('united_name_bag');
    case 'кг':
      return t('united_name_kilogram');
    case 'грн':
      return t('united_name_hryvnia');
    case 'шпр':
      return t('united_name_syringe');

    default:
      return name;
  }
};
export default useUnitNamesRedactor;
