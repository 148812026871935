import axios, { AxiosError } from 'axios';
import { SET_BLOG_POSTS, SET_BLOG_LOADING } from '../actionTypes';
import handleError from '../../utils/integrations/sentry';
import { ApiClient } from '../../api/ApiClient';
import { ApiGetResponse, Post } from '../responseTypes';
import { toast } from 'react-toastify';

export function fetchPostsByLocale(currentLocale: string) {
  return async (dispatch) => {
    dispatch(setBlogLoading(true));

    try {
      const response = await ApiClient.get<unknown, ApiGetResponse<Post>>(`/storage/blog`);

      const sortedData = response.data.filter(
        ({ active, locale }) => active && locale === currentLocale
      );
      dispatch(setBlogPosts(sortedData));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
      handleError(error as AxiosError);
    }
    dispatch(setBlogLoading(false));
  };
}

export function setBlogLoading(value) {
  return {
    type: SET_BLOG_LOADING,
    payload: value
  };
}

export function setBlogPosts(data) {
  return {
    type: SET_BLOG_POSTS,
    payload: data
  };
}
