import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';

const momentWithLocale = (...args) => {
  moment.locale(i18n.language === 'ua' ? 'uk' : i18n.language);

  return moment(...args).format('LL');
};
export default momentWithLocale;
