import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

import ErrorBoundaryWrapper from './ErrorBoundary';
import { SearchProductType } from '../api/search/types';
import { useSearch } from '../hooks/useSearch';
import { CircularProgress } from '@material-ui/core';
import { getNameByLocale } from '../utils/getNameByLocale';
import { useLocale } from '../hooks/useLocale';

const HeadSearch: React.FC = () => {
  const { t } = useTranslation();
  const { currentLangCode } = useLocale();

  const [showSearchList, setShowSearchList] = useState(false);

  const {
    isLoading,
    searchValue,
    products,
    recentSearches,
    handleSearchValueChange,
    handleSearchChange,
    handleProductClick,
    handleEnterPress,
    handleRedirectToSearchPage,
    handleRemoveRecentSearch
  } = useSearch();

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    handleSearchValueChange(value);
    handleSearchChange(value.trim());
  };

  const searchResults = () => {
    if (!products.length) return emptyResults();

    return (
      <div className="results">
        <ul>
          {products.slice(0, 10).map((product: SearchProductType) => (
            <li key={product.id} onClick={() => handleProductClick(product.id)}>
              {getNameByLocale(product, currentLangCode)}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const recentSearchResults = () => {
    if (!recentSearches.length) return;

    return (
      <div className="results recent">
        <ul>
          {recentSearches
            .slice(-5)
            .reverse()
            .map((item: string, index) => (
              <li key={index}>
                <p onClick={() => handleRedirectToSearchPage(item)}>{item}</p>
                <div onClick={() => handleRemoveRecentSearch(item)}>
                  <AiFillCloseCircle size={20} />
                </div>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  const emptyResults = () => (
    <div className="results">
      <p className="text-center">{t('empty_search_text')}</p>
    </div>
  );

  return (
    <div
      className="head-search"
      onFocus={() => setShowSearchList(true)}
      onBlur={() =>
        setTimeout(() => {
          setShowSearchList(false);
        }, 300)
      }
    >
      <ErrorBoundaryWrapper>
        <input
          type="text"
          name="search"
          value={searchValue}
          onChange={(e) => searchHandler(e)}
          placeholder={t('search_text')}
          className="input"
          autoComplete="off"
          onKeyPress={(e) => handleEnterPress(e, searchValue)}
        />
      </ErrorBoundaryWrapper>
      <div className="search-btn" onClick={() => handleRedirectToSearchPage(searchValue)}>
        <span>{isLoading ? <CircularProgress size={20} /> : <FaSearch size={20} />}</span>
      </div>
      {showSearchList && (searchValue === '' ? recentSearchResults() : searchResults())}
    </div>
  );
};

export default HeadSearch;
