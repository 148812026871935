import { combineReducers } from 'redux';
import categoriesReducer from './categories';
import productsReducer from './products';
import alertReducer from './alertMessage';
import globalReducer from './global';
import authorizationReducer from './authorization';
import wishlistReducer from './wishlist';
import blogReducer from './blog';
import searchReducer from './search';

export const rootReducer = combineReducers({
  cats: categoriesReducer,
  products: productsReducer,
  alert: alertReducer,
  global: globalReducer,
  auth: authorizationReducer,
  wishlist: wishlistReducer,
  blog: blogReducer,
  search: searchReducer
});

export type RootState = ReturnType<typeof rootReducer>;
