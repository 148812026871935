import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AiOutlineInstagram,
  AiOutlineMail,
  AiTwotonePhone,
  BiTimeFive,
  FaFacebookSquare,
  FaMobileAlt
} from 'react-icons/all';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/useLocale';

type Ref = HTMLElement | null;
const Footer = forwardRef<Ref, unknown>(function Footer(_, ref) {
  const { t } = useTranslation();
  const { currentLangCode } = useLocale();

  return (
    <footer ref={ref}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-title">{t('footer_column_internet_apteka')}</div>
            <ul>
              <li>
                <NavLink to={'/about'}>{t('footer_column_link_about_us')}</NavLink>
              </li>
              <li>
                <NavLink to={'/map'}>{t('footer_column_link_map')}</NavLink>
              </li>
              <li>
                <NavLink to={'/contacts'}>{t('footer_column_link_contacts')}</NavLink>
              </li>
              {/*<li><NavLink to={'/discounts'}>Акции</NavLink></li>*/}
              {/*<li><NavLink to={'/'}>Это интересно</NavLink></li>*/}
              <li>
                <NavLink to={'/performance'}>{t('footer_column_link_performance')}</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <div className="footer-title">{t('footer_column_cooperation')}</div>
            <ul>
              <li>
                <NavLink to={'/jobs'}>{t('footer_column_link_jobs')}</NavLink>
              </li>
              <li>
                <NavLink to={'/for-clients'}>{t('footer_column_link_for_clients')}</NavLink>
              </li>
              <li>
                <NavLink to={'/insurance'}>{t('footer_column_link_insurance')}</NavLink>
              </li>
              <li>
                <NavLink to={'/marketing'}>{t('footer_column_link_marketing')}</NavLink>
              </li>
              <li>
                <NavLink to={'/rent'}>{t('footer_column_link_rent')}</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <div className="footer-title">{t('footer_column_information')}</div>
            <ul>
              <li>
                <NavLink to={'/how-to-order'}>{t('footer_column_link_how_to_order')}</NavLink>
              </li>
              <li>
                <NavLink to={'/order-by-phone'}>{t('footer_column_link_order_by_phone')}</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <div className="footer-title">{t('footer_column_contacts')}</div>
            <ul className="footer-contacts">
              <li>
                <div className="footer-contacts-icon">
                  <AiTwotonePhone size={15} />
                </div>
                <a href="tel:0509310935">(050) 931-09-35</a>
              </li>
              <li>
                <div className="footer-contacts-icon">
                  <FaMobileAlt size={15} />
                </div>
                <a href="tel:0975270340">(097) 527-03-40</a>
              </li>
              <li>
                <div className="footer-contacts-icon">
                  <BiTimeFive size={15} />
                </div>
                <p>{t('footer_working_days')}</p>
              </li>
              <li>
                <div className="footer-contacts-icon">
                  <AiOutlineMail size={15} />
                </div>
                <a href="mailto:orders@aptslav.com.ua">orders@aptslav.com.ua</a>
              </li>
            </ul>
            <div className="socials">
              <div className="socials-title">{t('footer_column_socials')}</div>
              <div className="socials-links">
                <a
                  href="https://www.facebook.com/apteka.slavutich/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="footer-contacts-icon">
                    <FaFacebookSquare size={20} />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/apteka_slavutich/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="footer-contacts-icon">
                    <AiOutlineInstagram size={20} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyRights">
          <p>{t('footer_description')}</p>
          <p>©1995-2022 {t('footer_prescription')}</p>
        </div>
      </div>
      <div className="container text-center">
        <img
          src={`/images/${currentLangCode}/footer.svg`}
          alt={t('page_pharmacy_map_title_text1')}
        />
      </div>
    </footer>
  );
});

export default Footer;
