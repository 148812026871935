export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const SET_LOAD_PRODUCTS = 'SET_LOAD_PRODUCTS';
export const SET_BASKET_PRODUCTS = 'SET_BASKET_PRODUCTS';
export const SHOW_SUCCESS_ALERT = 'SHOW_SUCCESS_ALERT';
export const HIDE_SUCCESS_ALERT = 'HIDE_SUCCESS_ALERT';
export const SET_LOAD_BASKET = 'SET_LOAD_BASKET';
export const SET_HEADER_SEARCH_VALUE = 'SET_HEADER_SEARCH_VALUE';
export const SET_HEADER_SEARCH_PRODUCTS = 'SET_HEADER_SEARCH_PRODUCTS';
export const SET_RECENT_SEARCHES = 'SET_RECENT_SEARCHES';
export const SET_LOAD_MORE = 'SET_LOAD_MORE';
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const LOGOUT = 'LOGOUT';
export const GET_USER_ORDERS = 'GET_USER_ORDERS';
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING';
export const SET_WISHLIST = 'SET_WISHLIST';
export const SET_WISHLIST_LOADING = 'SET_WISHLIST_LOADING';
export const RESET_WISHLIST = 'RESET_WISHLIST';
export const SET_USER_PHARMACY = 'SET_USER_PHARMACY';
export const SET_PHARMACIES = 'SET_PHARMACIES';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const SET_BLOG_POSTS = 'SET_BLOG_POSTS';
export const SET_BLOG_LOADING = 'SET_BLOG_LOADING';
