import React from 'react';
import Ads from '../../assets/images/photo-ads.jpg';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">{t('page_marketing_title_text')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>{t('page_marketing_text_title1')}</p>
          <ul>
            <li>{t('page_marketing_list_text1')}</li>
            <li>{t('page_marketing_list_text2')}</li>
            <li>{t('page_marketing_list_text3')}</li>
            <li>{t('page_marketing_list_text4')}</li>
          </ul>
          <p>
            {t('page_marketing_text1')} <strong>e-mail: Lenam_2005@ukr.net</strong>
          </p>
        </div>
        <div className="col-md-6">
          <div className="singleImg">
            <img src={Ads} alt="License" />
          </div>
        </div>
      </div>
    </div>
  );
};
