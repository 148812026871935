import React from 'react';
import Phone from '../../assets/images/photo-zakaz-phone.jpg';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <h2 className="thinTitle">{t('page_order_by_phone_title_text')}</h2>
      <div className="row infoPage-content">
        <div className="col-md-6">
          <p>{t('page_order_by_phone_text1')}</p>
          <p>
            <strong>{t('page_order_by_phone_text2')}</strong>
          </p>
          <p>(050) 931-09-35</p>
          <p>(097) 527-03-40</p>
          <p>{t('page_order_by_phone_text3')}</p>
          <ul>
            <li>{t('page_order_by_phone_list_text1')}</li>
            <li>{t('page_order_by_phone_list_text2')}</li>
            <li>{t('page_order_by_phone_list_text3')}</li>
          </ul>
        </div>
        <div className="col-md-6">
          <div className="singleImg">
            <img src={Phone} alt="License" />
          </div>
        </div>
      </div>
    </div>
  );
};
