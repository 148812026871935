import { Modal } from '@material-ui/core';
import React, { useRef } from 'react';
import CallbackImg from '../assets/images/callback.jpg';
import { useTranslation } from 'react-i18next';
import useGetStoreData from '../hooks/useGetStoreData';
import InputComponent from './InputComponent';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { VALIDATION } from '../constants/validation';
import { toast } from 'react-toastify';
import { sendCallback } from '../api/SendCallback';
import { TIMEOUT_DELAY } from '../common/common';
import axios from 'axios';
import { phoneChecker } from '../utils/phoneChecker';

const FormSchema = Yup.object().shape({
  name: VALIDATION.text,
  phone: VALIDATION.phone
});

export default ({ open, close }) => {
  const { t } = useTranslation();

  const userData = useGetStoreData();

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const handleSubmit = async (data: { name: string; phone: string }) => {
    try {
      await sendCallback({ ...data, phone: phoneChecker(data.phone) });
      toast.success(t('admin_contact_asap_text'));
      close();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: userData?.name || '',
      phone: userData?.phone || ''
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      delayTimeoutRef.current = setTimeout(async () => await handleSubmit(values), TIMEOUT_DELAY);
    }
  });

  return (
    <Modal open={open} onClose={close}>
      <div className="modal">
        <div className="modal-title">
          <h3>{t('order_callback_title')}</h3>
        </div>
        <div className="modal-body">
          <div className="text-center" style={{ marginBottom: 20 }}>
            <img src={CallbackImg} title="Сеть аптек Славутич" alt="Сеть аптек Славутич " />
          </div>
          <div className="form-wrapper">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <InputComponent
                label={t('form_input_name')}
                onChange={formik.handleChange}
                value={formik.values.name}
                error={Boolean(formik.errors.name && formik.touched.name)}
                errorText={t('form_input_name_valid_msg')}
                type="text"
                name="name"
                isRequired
                onBlur={formik.handleBlur}
              />
              <InputComponent
                value={formik.values.phone}
                onChange={formik.handleChange}
                label={t('form_input_phone')}
                error={Boolean(formik.errors.phone && formik.touched.phone)}
                errorText={t('form_input_phone_valid_msg')}
                type="tel"
                name="phone"
                isRequired
                onBlur={formik.handleBlur}
              />
              <div className="buttons">
                <button type="submit" disabled={formik.isSubmitting} className="btn">
                  {t('order_callback_button_text')}
                </button>
                <button onClick={close} className="btn btn-error">
                  {t('cancelText')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
