import React, { useState } from 'react';
import { MyMapComponent } from '../../components/Map';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';
import * as _ from 'lodash';
import { PharmacyType } from '../../store/responseTypes';

export default () => {
  const { t } = useTranslation();

  const pharmacies = useSelector((state: RootState) => state.global.pharmacies).filter(
    ({ active }) => active
  );

  const [openPharmacyInfo, setOpenPharmacyInfo] = useState(0);
  return (
    <div className="page-wrapper">
      <SEO customTitle={t('page_pharmacy_map_title_text')} />
      <h2 className="thinTitle">{t('page_pharmacy_map_title_text')}</h2>
      <div className="map">
        <MyMapComponent>
          {pharmacies.map((item: PharmacyType) => (
            <Marker
              key={item.externalId}
              position={{ lat: +item.latitude, lng: +item.longitude }}
              onClick={() => setOpenPharmacyInfo(item.externalId)}
            >
              {openPharmacyInfo === item.externalId && (
                <InfoWindow>
                  <div
                    style={{ backgroundColor: `#ccc`, opacity: 0.85, padding: `10px` }}
                    onClick={() => setOpenPharmacyInfo(0)}
                  >
                    <div style={{ marginBottom: 15, fontSize: 16 }}>
                      <p>
                        <strong>{item.name}</strong>
                      </p>
                      <p>
                        {t('page_pharmacy_map_address_text')}: {item.address}
                      </p>
                      <p>
                        {t('page_pharmacy_map_phone_text')}: {item.phone}
                      </p>
                      {item.workingHours && (
                        <p>
                          {t('page_pharmacy_map_working_hours_text')}: {item.workingHours}
                        </p>
                      )}
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </MyMapComponent>
      </div>
      <h2 className="thinTitle">{t('page_pharmacy_map_title_text1')}</h2>
      <div className="map-list infoPage-content" style={{ marginTop: 30 }}>
        {_.orderBy(pharmacies, ['id'], ['asc']).map((item: PharmacyType) => (
          <div className="map-item" key={item.externalId} style={{ color: '#000' }}>
            <p>
              <strong>{item.name}</strong>
            </p>
            <p>
              {t('page_pharmacy_map_address_text')}: {item.address}
            </p>
            <p>
              {t('page_pharmacy_map_phone_text')}:{' '}
              <a style={{ color: '#000' }} href={`tel:${item.phone}`}>
                {item.phone}
              </a>
            </p>
            {item.workingHours && (
              <p>
                {t('page_pharmacy_map_working_hours_text')}: {item.workingHours}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
