import React, { useRef } from 'react';
import { VALIDATION } from '../../constants/validation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useGetStoreData from '../../hooks/useGetStoreData';
import { useFormik } from 'formik';
import InputComponent from '../InputComponent';
import { toast } from 'react-toastify';
import { sendCallback } from '../../api/SendCallback';
import { TIMEOUT_DELAY } from '../../common/common';

const FormSchema = Yup.object().shape({
  name: VALIDATION.text,
  email: VALIDATION.email,
  comment: VALIDATION.comment
});

interface IValue {
  name: string;
  email: string;
  comment: string;
}

const InfoPageForm = () => {
  const { t } = useTranslation();
  const userData = useGetStoreData();

  const delayTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>();

  const handleSubmit = async (data: IValue, { resetForm }) => {
    try {
      await sendCallback(data);
      toast.success(t('admin_contact_asap_text'));
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: userData?.name || '',
      email: userData?.email || '',
      comment: ''
    },
    validationSchema: FormSchema,
    onSubmit: (values, options) => {
      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      delayTimeoutRef.current = setTimeout(
        async () => await handleSubmit(values, options),
        TIMEOUT_DELAY
      );
    }
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <InputComponent
        onChange={formik.handleChange}
        value={formik.values.name}
        placeholder={t('form_input_name')}
        error={Boolean(formik.errors.name && formik.touched.name)}
        errorText={t('form_input_name_valid_msg')}
        type="text"
        name="name"
        isRequired
        onBlur={formik.handleBlur}
      />

      <InputComponent
        value={formik.values.email}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.email && formik.touched.email)}
        errorText={t('form_input_email_valid_msg')}
        placeholder={t('form_input_email')}
        type="email"
        name="email"
        isRequired
        onBlur={formik.handleBlur}
      />
      <textarea
        className="input"
        placeholder={t('form_input_message')}
        onChange={formik.handleChange}
        value={formik.values.comment}
        name="comment"
      />
      <div className="flex-container" style={{ marginTop: '20px', justifyContent: 'center' }}>
        <button type="submit" className="btn">
          {t('page_contacts_button_send')}
        </button>
      </div>
    </form>
  );
};

export default InfoPageForm;
