import { LOGOUT } from '../actionTypes';
import { SET_LOGIN_DATA } from '../actionTypes';

const initialState = {
  user: null,
  isAuth: false
};

export default function authorizationReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_LOGIN_DATA:
      return {
        ...state,
        user: action.payload,
        isAuth: true
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
