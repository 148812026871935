import { useEffect, useMemo } from 'react';

const useIsInViewport = (ref, setIsFooterState: (state: boolean) => void) => {
  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsFooterState(entry.isIntersecting)),
    [setIsFooterState]
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);
};
export default useIsInViewport;
