import React, { useEffect, useState } from 'react';
import Select, { CSSObjectWithLabel } from 'react-select';

export interface OptionProps {
  value: string;
  label: string;
}

export type SelectedProps = OptionProps | null | undefined;

export interface SelectDropdownProps {
  options: Array<OptionProps>;
  onClick?: (selectedOption: SelectedProps) => void;
  disabled?: boolean;
  placeholder?: string;
  classes?: string;
  selected?: SelectedProps;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  onClick = () => null,
  disabled,
  placeholder,
  classes,
  selected
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectedProps>(selected);

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  const handleChange = (selectedOption: SelectedProps) => {
    setSelectedOption(selectedOption);
    onClick(selectedOption);
  };

  const customStyles = {
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      margin: 0
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: 'white'
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: 'white'
    }),
    option: (provided: CSSObjectWithLabel, state: any) => {
      const getColor = () => {
        if (state.isSelected) return '#e1dcc8';
        if (state.isFocused) return '#f6f5ef';
        return '#fff';
      };
      return {
        ...provided,
        backgroundColor: getColor(),
        color: '#000',
        outline: 'none',
        ':active': {
          backgroundColor: '#f6f5ef'
        }
      };
    },
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      background: 'none',
      border: 'none',
      boxShadow: 'none'
    })
  };

  return (
    <Select
      isSearchable={false}
      value={selectedOption}
      onChange={handleChange}
      options={options}
      className={classes}
      styles={customStyles}
      isDisabled={disabled}
      placeholder={placeholder}
    />
  );
};
