import Catalog from './views/Catalog/Catalog';
import Product from './views/Product/Product';
import OrderCreation from './views/OrderCreation/OrderCreation';
import Home from './views/Home/Home';
import Authorization from './views/Authorization/Authorization';
import Profile from './views/Profile/Profile';
import Registration from './views/Registration/Registration';
import About from './views/InfoPages/About';
import Contacts from './views/InfoPages/Contacts';
import CorporationClients from './views/InfoPages/CorporationClients';
import DiscountPrograms from './views/InfoPages/DiscountPrograms';
import Discounts from './views/InfoPages/Discounts';
import HowToOrder from './views/InfoPages/HowToOrder';
import InsuranceCompanies from './views/InfoPages/InsuranceCompanies';
import Jobs from './views/InfoPages/Jobs';
import Marketing from './views/InfoPages/Marketing';
import OrderByPhone from './views/InfoPages/OrderByPhone';
import PharmaciesMap from './views/InfoPages/PharmaciesMap';
import ProductsPerformance from './views/InfoPages/ProductsPerformance';
import Rent from './views/InfoPages/Rent';
import Search from './views/Search/Search';
import Articles from './views/Blog/Articles';
import ArticlePost from './views/Blog/ArticlePost';

const routes: any[] = [
  {
    path: '',
    title: 'Home',
    component: Home,
    isExact: true
  },
  {
    path: 'products/:categoryId/:subcategoryId?',
    title: 'Catalog',
    component: Catalog,
    isExact: false
  },
  {
    path: 'product/:id',
    title: 'ProductView',
    component: Product,
    isExact: false,
    params: {
      reduxEntity: 'product'
    }
  },
  {
    path: 'create-order',
    title: 'Order creation',
    component: OrderCreation,
    isExact: false
  },
  {
    path: 'authorization',
    title: 'Authorization',
    component: Authorization,
    isExact: false
  },
  {
    path: 'profile',
    title: 'Profile',
    component: Profile,
    isExact: false
  },

  {
    path: 'registration',
    title: 'Registration',
    component: Registration,
    isExact: false
  },

  {
    path: 'about',
    title: 'About',
    component: About,
    isExact: false
  },
  {
    path: 'contacts',
    title: 'Contacts',
    component: Contacts,
    isExact: false
  },
  {
    path: 'for-clients',
    title: 'For Clients',
    component: CorporationClients,
    isExact: false
  },
  {
    path: 'discount-programs',
    title: 'Discount programs',
    component: DiscountPrograms,
    isExact: false
  },
  {
    path: 'discounts',
    title: 'Discounts',
    component: Discounts,
    isExact: false
  },
  {
    path: 'how-to-order',
    title: 'How to order',
    component: HowToOrder,
    isExact: false
  },
  {
    path: 'insurance',
    title: 'Insurance',
    component: InsuranceCompanies,
    isExact: false
  },
  {
    path: 'jobs',
    title: 'Jobs',
    component: Jobs,
    isExact: false
  },
  {
    path: 'marketing',
    title: 'Marketing',
    component: Marketing,
    isExact: false
  },
  {
    path: 'order-by-phone',
    title: 'Order by phone',
    component: OrderByPhone,
    isExact: false
  },
  {
    path: 'map',
    title: 'Map',
    component: PharmaciesMap,
    isExact: false
  },
  {
    path: 'performance',
    title: 'Performance',
    component: ProductsPerformance,
    isExact: false
  },
  {
    path: 'rent',
    title: 'Rent',
    component: Rent,
    isExact: false
  },
  {
    path: 'search',
    title: 'Search',
    component: Search,
    isExact: false
  },
  {
    path: 'posts',
    title: 'Blog',
    component: Articles,
    isExact: true
  },
  {
    path: 'post/:slug',
    title: 'ArticlePost',
    component: ArticlePost,
    isExact: false
  }
];

export default routes;
