import React from 'react';
import ArticleItem from './ArticleItem';
import { NavLink } from 'react-router-dom';
import { Post } from '../../store/responseTypes';
import { useTranslation } from 'react-i18next';

type MainArticleProps = Post & {
  isLinkVisible?: boolean;
};

const MainArticle = ({
  image,
  title,
  description,
  slug,
  isLinkVisible = true
}: MainArticleProps) => {
  const { t } = useTranslation();

  return (
    <div className="article">
      <div className="article-image-container">
        {image && <img className="article-image" src={image} alt={title} />}
      </div>

      <div className="articleItem-content-wrapper pl">
        <h1 className="article-title">{title}</h1>

        <p className="article-description">{description}</p>

        {isLinkVisible && (
          <NavLink to={`/post/${slug}`}>
            <span>{t('blog_read_more')}</span>
          </NavLink>
        )}
      </div>
    </div>
  );
};
const ArticlePreview = (post: Post) => (
  <>{post.isMain ? <MainArticle {...post} /> : <ArticleItem {...post} />}</>
);

export { ArticlePreview, MainArticle };
